import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import CourseContext from '../../contexts/CourseContext';
import CategoryContext from '../../contexts/CategoryContext';
import { Accordion, Modal } from 'react-bootstrap';
import API_URL from '../../ApuUrl';

function CourseEditModals({ courseIdd }) {
    const [lgShow, setLgShow] = useState(false)
    const { allCourses, getCourse, setGetCourse, CourseById } = useContext(CourseContext);
    const { category } = useContext(CategoryContext);
    const [outcome, setOutcome] = useState(getCourse?.outcome || [""]);
    const [prereqs, setPrereqs] = useState(getCourse?.prereqs || [""]);
    const [target, setTarget] = useState(getCourse?.target || [""]);

    useEffect(() => {
        if (getCourse) {
            setOutcome(getCourse.outcome || []);
            setPrereqs(getCourse.prereqs || []);
            setTarget(getCourse.target || []);
        }
    }, [getCourse]);

    const updateCourse = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });

        if (isConfirmed) {
            const {
                title,
                duration,
                level,
                description,
                categoryId,
                image,
            } = getCourse;
            const formData = new FormData();
            formData.append("title", title);
            formData.append("duration", duration);
            formData.append("level", level);
            formData.append("name", name);
            formData.append("description", description);
            formData.append("categoryId", categoryId);
            formData.append("image", image);
            outcome.forEach((item) => formData.append("outcome[]", item));
            prereqs.forEach((item) => formData.append("prereqs[]", item));
            target.forEach((item) => formData.append("target[]", item));

            await fetch(
                `${API_URL}/api/course/updatecourse/${getCourse._id}`,
                {
                    method: "PUT",
                    body: formData,
                }
            );
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }
        allCourses();
    };

    const onchnge = (e) => {
        if (e.target.files) {
            setGetCourse({ ...getCourse, image: e.target.files[0] });
        } else {
            setGetCourse({ ...getCourse, [e.target.name]: e.target.value });
        }
    };

    const handleChangeOutcome = (e, index) => {
        const newOutcome = [...outcome];
        newOutcome[index] = e.target.value;
        setOutcome(newOutcome);
    };
    const handleAddOutcome = () => {
        setOutcome([...outcome, '']);
    };

    const handleChangePrereq = (e, index) => {
        const newPrereqs = [...prereqs];
        newPrereqs[index] = e.target.value;
        setPrereqs(newPrereqs);
    };
    const handleAddPrereq = () => {
        setPrereqs([...prereqs, '']);
    };

    const handleChangeTarget = (e, index) => {
        const newTarget = [...target];
        newTarget[index] = e.target.value;
        setTarget(newTarget);
    };
    const handleAddTarget = () => {
        setTarget([...target, '']);
    };
    const editModalId = (id) => {
        CourseById(courseIdd)
        setLgShow(true)
    }
    return (
        <>
            {/* {/ {/ Edit modal /} /} */}
            <i
                className="fa fa-pen me-2"
                onClick={() => editModalId()}
            ></i>
            <Modal
                size="xl"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Edit Course
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form
                        className="edit-course-form new-add-course-form"
                        onSubmit={updateCourse}
                        encType="multipart/form-data"
                    >
                        <h5 className="fw-light mb-3" style={{ textDecoration: "underline" }}>
                            Course Introduction
                        </h5>
                        <div className="row mb-3 align-items-center">
                            <div className="col-md-4">
                                <label htmlFor="title" className="form-label">
                                    Course Title <span className="text-danger">*</span>
                                </label>
                                <input
                                    id="title"
                                    name="title"
                                    className="form-control"
                                    placeholder="Give a catchy course title"
                                    type="text"
                                    value={getCourse.title}
                                    onChange={onchnge}
                                />
                                <span id="error" className="text-danger"></span>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="categoryId" className="form-label">
                                    Course Category <span className="text-danger">*</span>
                                </label>
                                <select
                                    id="categoryId"
                                    className="form-control"
                                    name="categoryId"
                                    value={getCourse.categoryId}
                                    onChange={onchnge}
                                >
                                    <option value="">Select Category</option>
                                    {category &&
                                        category.map((data) => (
                                            <option key={data._id} value={data._id}>
                                                {data.category}
                                            </option>
                                        ))}
                                </select>
                                <span id="error" className="text-danger"></span>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="duration" className="form-label">
                                    Course Duration <span className="text-danger">*</span>
                                </label>
                                <input
                                    id="duration"
                                    name="duration"
                                    className="form-control"
                                    placeholder="Your course duration"
                                    type="text"
                                    value={getCourse.duration}
                                    onChange={onchnge}
                                />
                                <span id="error" className="text-danger"></span>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 mt-3">
                                <label htmlFor="level" className="form-label">
                                    Course Level <span className="text-danger">*</span>
                                </label>
                                <select
                                    id="level"
                                    className="form-control"
                                    name="level"
                                    value={getCourse.level}
                                    onChange={onchnge}
                                >
                                    <option value="">Select Course Level</option>
                                    <option value="beginner">Beginner</option>
                                    <option value="intermediate">Intermediate</option>
                                    <option value="advanced">Advanced</option>
                                </select>
                                <span id="error" className="text-danger"></span>
                            </div>

                            <div className="col-md-4 mt-3">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    placeholder="Give your name for public view"
                                    type="text"
                                    value={getCourse.name}
                                    onChange={onchnge}
                                />
                                <span id="error" className="text-danger"></span>
                            </div>

                            <div className="col-md-4 mt-3">
                                <label htmlFor="image" className="form-label">
                                    Featured Image
                                </label>
                                <input
                                    id="image"
                                    name="image"
                                    className="form-control"
                                    type="file"
                                    title="Upload course featured image"
                                    onChange={onchnge}
                                />
                                <span id="error" className="text-danger"></span>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <label htmlFor="description" className="form-label">
                                Course Description <span className="text-danger">*</span>
                            </label>
                            <textarea
                                id="description"
                                name="description"
                                className="form-control"
                                placeholder="Tell a bit about your course"
                                value={getCourse.description}
                                onChange={onchnge}
                            />
                            <span id="error" className="text-danger"></span>
                        </div>

                        <h5 className="fw-light my-3" style={{ textDecoration: "underline" }}>
                            Intended Learners
                        </h5>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item className="form-accord" eventKey="0">
                                        <Accordion.Header>
                                            What will the students learn from your course?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <label htmlFor="outcome" className="form-label">
                                                Learning Outcome <span className="text-danger">*</span>
                                            </label>
                                            {outcome.map((outcomeValue, index) => (
                                                <div key={index}>
                                                    <input
                                                        id={`outcome-${index}`}
                                                        type="text"
                                                        className="form-control mb-3"
                                                        name="outcome"
                                                        value={outcomeValue}
                                                        onChange={(e) => handleChangeOutcome(e, index)}
                                                    />
                                                </div>
                                            ))}<br />
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleAddOutcome}
                                            >
                                                + Add more to your response
                                            </span>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12">
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item className="form-accord" eventKey="0">
                                        <Accordion.Header>
                                            What are the requirements or prerequisites for this course?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <label htmlFor="prereq" className="form-label">
                                                Prerequisite
                                            </label>
                                            {prereqs.map((prereqValue, index) => (
                                                <div key={index}>
                                                    <input
                                                        id={`prereq-${index}`}
                                                        className="form-control mb-3"
                                                        type="text"
                                                        value={prereqValue}
                                                        onChange={(e) => handleChangePrereq(e, index)}
                                                    />
                                                </div>
                                            ))}<br />
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleAddPrereq}
                                            >
                                                + Add more to your response
                                            </span>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-12">
                                <Accordion defaultActiveKey="">
                                    <Accordion.Item className="form-accord" eventKey="0">
                                        <Accordion.Header>Who is this course for?</Accordion.Header>
                                        <Accordion.Body>
                                            <label htmlFor="target" className="form-label">
                                                Target Audience <span className="text-danger">*</span>
                                            </label>
                                            {target.map((targetValue, index) => (
                                                <div key={index}>
                                                    <input
                                                        id={`target-${index}`}
                                                        className="form-control mb-3"
                                                        type="text"
                                                        value={targetValue}
                                                        onChange={(e) => handleChangeTarget(e, index)}
                                                    />
                                                </div>
                                            ))}<br />
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleAddTarget}
                                            >
                                                + Add more to your response
                                            </span>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>

                        <div className="text-center">
                            <button type='submit' className="first-button mt-3">Update Course</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CourseEditModals
