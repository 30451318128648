import React, { useContext, useState } from 'react'
import ConfigContext from '../../contexts/ConfigCerti'
import { Modal } from 'react-bootstrap'
import API_URL from '../../ApuUrl'

export default function AllConfigCerti() {
    const { configCerti, getConfiCertiById, confiCertiById, delConfiCerti, setConfiCertiById, uploadedConfigCerti } = useContext(ConfigContext)
    const [viewCertificate, setViewCertificate] = useState(false)
    const [editCertificate, setEditCertificate] = useState(false)

    const viewGetId = (id) => {
        setViewCertificate(true)
        getConfiCertiById(id)
    }
    const editGetId = (id) => {
        setEditCertificate(true)
        getConfiCertiById(id)
    }

    const submitConfigCerti = async (e) => {
        e.preventDefault()
        const { description, configureDate } = confiCertiById
        const res = await fetch(`${API_URL}/api/configCerti/updateConfCerti/${confiCertiById._id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ description, configureDate })
        })
        if (res.ok) {
            uploadedConfigCerti()
        }
    }

    const onchange = (e) => {
        const { name, value } = e.target
        setConfiCertiById({ ...confiCertiById, [name]: value })
    }

    const formateDate = (date) => {
        return new Date(date).toLocaleDateString()
    }
    const generateEditDate = (date) => {
        if (!date) return '';
        const today = new Date(date);
        if (isNaN(today.getTime())) return '';
        return today.toISOString().split('T')[0];
    }

    return (
        <>
            <div className="mt-3">
                <div className="table-container" style={{ overflowX: "auto" }}>
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">Sr#</th>
                                <th scope="col">Configure Date</th>
                                <th scope="col">Course Title</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {configCerti?.map((certi, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{formateDate(certi.configureDate)}</td>
                                    <td>{certi.courseId?.title}</td>
                                    <td>
                                        <i className="fa fa-eye me-2" onClick={() => viewGetId(certi._id)}></i>
                                        <i className="fa fa-edit me-2" onClick={() => editGetId(certi._id)}></i>
                                        <i className="fa fa-trash" onClick={() => delConfiCerti(certi._id)}></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* confi certi view modal */}
            <Modal
                size="lg"
                show={viewCertificate}
                onHide={() => setViewCertificate(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Configure Certificate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div id="certiError" className='text-danger'></div>
                        <label htmlFor="courseId">Configured Course </label>
                        <input type="text" value={confiCertiById.courseId?.title} className='my-3 form-control' />
                        <label htmlFor="courseId">Configured Date </label>
                        <input type="text" value={formateDate(confiCertiById.configureDate)} className='my-3 form-control' />
                        <label htmlFor="courseId">Description</label>
                        <textarea name="description" value={confiCertiById.description} rows={10} className='form-control my-3' placeholder='Description goes here'></textarea>
                    </form>
                </Modal.Body>
            </Modal>

            {/* confi certi edit modal */}
            <Modal
                size="lg"
                show={editCertificate}
                onHide={() => setEditCertificate(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Configure Certificate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitConfigCerti}>
                        <div id="certiError" className='text-danger'></div>
                        <label htmlFor="courseId">Configured Title</label>
                        <input type="text" value={confiCertiById.courseId?.title} className='my-3 form-control' />
                        <label htmlFor="courseId">Configured Date</label>
                        <input type="date" name='configureDate' value={generateEditDate(confiCertiById.configureDate)} className='mb-3 form-control' onChange={onchange} />
                        <label htmlFor="courseId">Configured Description</label>
                        <textarea name="description" value={confiCertiById.description} rows={10} className='form-control mb-3' placeholder='Description goes here' onChange={onchange}></textarea>
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-primary' type='submit'>Update</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
