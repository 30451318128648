import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ResultContext from './ResultContext'
import API_URL from '../ApuUrl'

function ResultProvider({ children }) {
    const [lessonCourseId, setLessonCourseId] = useState("")
    const [results, setResults] = useState([])
    const [resultById, setResultById] = useState([])

    const uploadedResults = async () => {
        const res = await fetch(`${API_URL}/api/result/getResults`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setResults(data)
    }
    const getResultById = async (id) => {
        const res = await fetch(`${API_URL}/api/result/getResult/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setResultById(data)
    }
    const delSubmitTask = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your course has been deleted successfully.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(
                `${API_URL}/api/submit/delSubmitTask/${id}`,
                {
                    method: "DELETE",
                }
            );
            uploadedResults();
        }
    }

    useEffect(() => {
        uploadedResults();
    }, [])


    return (
        <ResultContext.Provider value={{ lessonCourseId, setLessonCourseId, getResultById, resultById, results, delSubmitTask, uploadedResults, setResultById }}>
            {children}
        </ResultContext.Provider>
    )
}

export default ResultProvider
