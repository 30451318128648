import React, { useContext } from 'react'
import Swal from 'sweetalert2';
import userContext from '../../contexts/UseContext';
import API_URL from '../../ApuUrl';

export default function UserModals() {
    const { getUser, getAllUsers, setGetUser } = useContext(userContext)
    const updateUser = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });

        if (isConfirmed) {
            const { name, number, password, role, image } = getUser
            const formData = new FormData()
            formData.append("name", name)
            formData.append("email", email)
            formData.append("password", password)
            formData.append("role", role)
            formData.append("number", number)
            formData.append("image", image)
            const res = await fetch(`${API_URL}/api/user/updateuser/${getUser._id}`, {
                method: "PUT",
                body: formData
            })
            const data = await res.json()
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }
        getAllUsers()
    }

    const onchnge = (e) => {
        if (e.target.name === 'number') {
            const formattedValue = e.target.value.replace(/\D/g, '');

            // Restrict to 11 numbers
            const truncatedValue = formattedValue.slice(0, 11);

            // Format as "0000-000000000"
            const formattedNumber = truncatedValue.replace(/(\d{4})(\d{0,7})(\d{0,4})/, (_, p1, p2, p3) => {
                return p2 ? `${p1}-${p2}${p3 ? `-${p3}` : ''}` : p1;
            });
            setGetUser((getUser) => ({ ...getUser, [e.target.name]: formattedNumber }));
        } else if (e.target.files) {
            setGetUser({ ...getUser, image: e.target.files[0] });
        } else {
            setGetUser({ ...getUser, [e.target.name]: e.target.value });
        }
    };
    return (
        <>
            {/* {/ view modal /} */}
            <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">View User</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div id="error" className="text-danger text-center"></div>
                                <label htmlFor="name" className="mt-3">Name</label>
                                <input className="form-control" value={getUser.name} id="name" type="name" placeholder="Name" />
                                <label htmlFor="email" className="mt-3">Email</label>
                                <input className="form-control" value={getUser.email} id="email" type="email" placeholder="Email" />
                                <label htmlFor="number" className="mt-3">Contact Number</label>
                                <input className="form-control" value={getUser.number} id="number" type="tel" placeholder="Number" />
                                <div id="emailError" className="text-danger"></div>
                                <label htmlFor="password" className="mt-3">User Password</label>
                                <input className="form-control" value={getUser.password} type="password" placeholder="Password" />
                                <div id="passwordError" className="text-danger"></div>
                                <label htmlFor="role" className="mt-3">Role</label>
                                <input className="form-control" value={getUser.role} type="text" placeholder="role" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* {/ Edit modal /} */}
            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Edit User</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div id="error" className="text-danger text-center"></div>
                                <label htmlFor="name" className="mt-3">Name</label>

                                <input className="form-control" name="name" value={getUser.name} onChange={onchnge} id="name" type="name" placeholder="Name" />
                                <label htmlFor="email" className="mt-3">Email</label>
                                <input className="form-control" value={getUser.email} id="email" type="email" placeholder="Email" />
                                <div id="emailError" className="text-danger"></div>
                                <label htmlFor="number" className="mt-3">Contact Number</label>
                                <input className="form-control" name="number" value={getUser.number} onChange={onchnge} id="number" type="tel" placeholder="Number" />
                                <div id="emailError" className="text-danger"></div>
                                <label htmlFor="password" className="mt-3">User Image</label>
                                <div className='d-flex justify-content-between'>
                                    <input className="form-control" name="image" onChange={onchnge} type="file" placeholder="Image" style={{ width: "80%" }} />
                                    {getUser.image && (
                                        <img src={getUser.image} style={{ width: "100px" }} alt="" />
                                    )}
                                </div>
                                <label htmlFor="password" className="mt-3">User Password</label>
                                <input className="form-control" name="password" value={getUser.password} onChange={onchnge} type="password" placeholder="Password" />
                                <div id="passwordError" className="text-danger"></div>
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary mt-3" onClick={updateUser}> Update User</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
