import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import CourseContext from '../../contexts/CourseContext';
import API_URL from '../../ApuUrl';
import ConfigContext from '../../contexts/ConfigCerti';
import GeneratedCertificate from './GeneratedCertificates';
import GenContext from '../../contexts/GenCertiContext';

export default function GenerateCertificate() {
    const [lgShow, setLgShow] = useState(false);
    const { configCerti } = useContext(ConfigContext)
    const { GeneratedCertificates } = useContext(GenContext)
    const generateCertDate = () => {
        const today = new Date()
        return today.toISOString().split('T')[0];
    }

    const [genCredentials, setGenCredentials] = useState({
        courseId: "",
        status: "Generate",
        generateDate: generateCertDate()
    })

    const CoursesToConfig = [
        { value: "", label: "Select course" },
        ...configCerti?.map(course => (
            { value: course.courseId?._id, label: course.courseId?.title }
        ))
    ]

    const submitConfigCerti = async (e) => {
        e.preventDefault()
        const { courseId, generateDate } = genCredentials
        const res = await fetch(`${API_URL}/api/genCerti/generateCertificate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ courseId, generateDate })
        })
        const data = await res.json()
        const genError = document.getElementById("genError")
        data.message !== undefined ? genError.innerText = data.message : genError.innerText = ""
        if (res.ok) {
            setGenCredentials({
                courseId: "",
            })
            GeneratedCertificates()
        }
    }

    const onchange = (e) => {
        const { name, value } = e.target;
        setGenCredentials({ ...genCredentials, [name]: value })
    }
    return (
        <>
            <div className="d-flex justify-content-end my-4">
                <Button onClick={() => setLgShow(true)}>Generate Certificate</Button>
            </div>
            <GeneratedCertificate />
            {/* config certificate modal */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Configure Certificate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitConfigCerti}>
                        <div id="genError" className='text-danger'></div>
                        <label htmlFor="courseId">Select Configured Course <span className='text-danger'>*</span></label>
                        <select name="courseId" value={genCredentials.courseId} className='form-control my-3' onChange={onchange}>
                            {CoursesToConfig?.map((option, ind) => (
                                <option key={ind} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <label htmlFor="courseId">Generated Date <span className='text-danger'>*</span></label>
                        <input type="date" name='generateDate' value={genCredentials.generateDate} className='form-control my-3' onChange={onchange} />
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-primary' type='submit'>Submit</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}
