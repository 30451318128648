import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import InstructorContext from './InstructorContext'
import MyContext from './Mycontexts'
import API_URL from '../ApuUrl'

export default function InstructorProvider({ children }) {
    const { signUser } = useContext(MyContext)
    const [allTeacher, setAllTeacher] = useState([])
    const [teacherById, setTeacherById] = useState([])
    const [techerApprove, setTeacherApprove] = useState([])

    // {Teacher}
    const allTeachers = async () => {
        await fetch(`${API_URL}/api/teacher/getAllTeachers`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => setAllTeacher(data))
    }

    const TeacherByIdFn = async (id) => {
        await fetch(`${API_URL}/api/teacher/getTeacher/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => setTeacherById(data))
    }

    const deleteTeacher = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your request has been deleted successfully",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(`${API_URL}/api/teacher/deleteTeacher/${id}`, {
                method: "delete"
            })
            TeacherApprovedFn()
            allTeachers()
        }
    }

    const TeacherApprovedFn = async () => {
        const res = await fetch(`${API_URL}/api/teacher/checkteacher/${signUser && signUser._id}`,
            {
                method: "GET"
            })
        const data = await res.json()
        setTeacherApprove(data)
    }

    useEffect(() => {
        allTeachers();
    }, [])

    // {Course}
    return (
        <InstructorContext.Provider value={{ allTeacher, teacherById, allTeachers, TeacherByIdFn, deleteTeacher, TeacherApprovedFn, techerApprove, setTeacherById }}>
            {children}
        </InstructorContext.Provider>
    )
}
