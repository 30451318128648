import { React, useContext, useState } from 'react'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TaskContext from '../../contexts/TaskController';
import MyContext from '../../contexts/Mycontexts';
import API_URL from '../../ApuUrl';

function AddTasksModal({ lessonId, courseId }) {
    const [lgShow, setLgShow] = useState(false);
    const { signUser } = useContext(MyContext)
    const [task, setTask] = useState({
        title: "", description: "", dueDate: "", file: ""
    })
    const [descHtml, setDescHtml] = useState("");
    const { assignedTasks } = useContext(TaskContext)

    // Assign Task
    const addTask = async (e) => {
        e.preventDefault()
        const { title, dueDate, file } = task
        const description = descHtml
        const totalMarks = 100

        const formData = new FormData()
        formData.append("title", title)
        formData.append("description", description)
        formData.append("dueDate", dueDate)
        formData.append("courseId", courseId)
        formData.append("userId", signUser?._id)
        formData.append("lessonId", lessonId)
        formData.append("totalMarks", totalMarks)
        formData.append("file", file)

        const res = await fetch(`${API_URL}/api/task/assignTasks`, {
            method: "POST",
            body: formData
        })
        const data = await res.json()
        const taskError = document.getElementById("taskError")
        data.message !== undefined ? taskError.innerText = data.message : taskError.innerText = ""
        if (res.ok) {
            setTask({
                title: "",
                dueDate: ""
            })
            setDescHtml("")
            Swal.fire({
                position: "center",
                icon: "success",
                title: "task has been added",
                showConfirmButton: false,
                timer: 1500
            });
            assignedTasks()
        }
    }

    const onChangetask = (e) => {
        const { files, name, value } = e.target
        if (files) {
            setTask({ ...task, file: files[0] });
        } else {
            setTask({ ...task, [name]: value });
        }
    };

    const handleDescChange = (html) => {
        setDescHtml(html);
    };

    const openModalAddId = () => {
        setLgShow(true)
    }
    return (
        <div>
            <div id="errohandle"></div>
            <Button onClick={() => openModalAddId()}>Assign Tasks</Button>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <h5>Assign New Tasks</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="taskError" className='text-danger'></div>
                    <form onSubmit={addTask}>
                        <div className="mb-3">
                            <label className="mb-2">
                                Title
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" className="form-control" placeholder="task Title" name="title" value={task.title} onChange={onChangetask} />
                        </div>
                        <div className="mb-3">
                            <label className="mb-2">
                                File
                            </label>
                            <input type="file" className="form-control" placeholder="task Title" name="file" onChange={onChangetask} />
                        </div>
                        <div className="mb-3">
                            <input type='date' className="form-control" placeholder="Due Date" name="dueDate" value={task.dueDate} onChange={onChangetask}></input>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2">
                                Assignment/Task
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <ReactQuill
                                className="mb-3"
                                theme="snow"
                                name="description"
                                value={descHtml}
                                onChange={handleDescChange}
                            />
                        </div>

                        <div className="d-flex justify-content-center">
                            <button type="submit" className=" first-button">Assign Task</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddTasksModal
