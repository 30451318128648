import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../contexts/Mycontexts";
import approvalWait from '../../img/approval-wait.avif'
import { Link } from "react-router-dom";
import API_URL from "../../ApuUrl";

export default function UserEnrolledCourses() {
    const { signUser } = useContext(MyContext)
    const [enrollCourse, setEnrollCourse] = useState([])
    console.log(enrollCourse);

    const [checkNotEnrol, setCheckNotEnrol] = useState(false)
    const [checkNotApprov, setCheckNotApprov] = useState(false)
    const EnrollCourse = async () => {
        const res = await fetch(`${API_URL}/api/enrollCourse/getenrol/${signUser._id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        console.log(data);


        if (data.message === "Not found any enroll course") {
            setCheckNotEnrol("Not found any enroll course")
        }
        if (Array.isArray(data) && data.length > 0 && 'message' in data[0]) {
            setCheckNotApprov(data[0].message);
        }
        setEnrollCourse(data)

    }

    useEffect(() => {
        EnrollCourse()
    }, [])


    if (checkNotEnrol) {
        return <div>

            <div className="container request-approval">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-4">
                        <img src={approvalWait} alt="wait-for-approval" className="img-fluid wiat-img" />
                    </div>
                    <div className="col-md-8">
                        <div className="section-header">
                            <h2>Hmm..!! You Can See Your Enrollment Course After Sending Request For Course Enrollment</h2>
                        </div>
                        <p>You will be able to see your details as soon as you send Your request!</p>
                    </div>
                </div>

            </div>
        </div>
    }
    if (checkNotApprov && !enrollCourse.length > 0) {
        return <div>

            <div className="container request-approval">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-4">
                        <img src={approvalWait} alt="wait-for-approval" className="img-fluid wiat-img" />
                    </div>
                    <div className="col-md-8">
                        <div className="section-header">
                            <h2>Hmm..!! You Can See Your Enrolled Course Once You're Approved By Admin</h2>
                        </div>
                        <p>You will be able to see your enrolled course as soon as your request to join the platform is accepted by the admin. Please keep in touch!</p>
                    </div>
                </div>

            </div>
        </div>
    }

    return (
        <div className="container">
            <div className="row">
                {enrollCourse && enrollCourse.filter(data => !data.message).map((status, index) => (
                    <div className="col-md-6 py-3" key={index}>
                        {status.course && (
                            <Link to={`/enrolled-course-page/${status.course.title.replace(/ /g, '-')}`}>
                                <div className="card courses">
                                    <div className="card-body course-content">
                                        <h3 className="card-title course-title mt-3">{status.course.title <= 20 ? status.course.title : status.course.title.slice(0, 20)}</h3>
                                        <hr style={{ width: '80%' }} />
                                        <h6 className="card-subtitle mb-2 text-muted">({status.course.instructorName ? status.course.instructorName : status.course.name})</h6>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                ))}
            </div>
        </div >
    )
}



