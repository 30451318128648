import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import CategoryContext from "../../contexts/CategoryContext";
import AddLessonModal from "../Lesson/AddLessonModal";
import CourseModals from "./CourseModals";
import CourseContext from "../../contexts/CourseContext";
import Accordion from "react-bootstrap/Accordion";
import MyContext from "../../contexts/Mycontexts";
import { Modal } from "react-bootstrap";
import CourseEditModals from "./CourseEditModal";
import API_URL from "../../ApuUrl";

export default function Addcourse() {
  const { category, AllCategory } = useContext(CategoryContext);
  const { signUser } = useContext(MyContext)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { deleteCourse, CourseById, allCourse, courseCategory, allCourses } =
    useContext(CourseContext);

  const [searchOption, setSearchOption] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [course, setCourse] = useState({
    title: "",
    duration: "",
    level: "",
    name: "",
    description: "",
    image: "",
    categoryId: "",
  });
  const [outcome, setOutcome] = useState(['']);
  const [prereqs, setPrereqs] = useState(['']);
  const [target, setTarget] = useState(['']);

  // add course
  const addCourse = async (e) => {
    e.preventDefault();
    const {
      title,
      duration,
      level,
      description,
      image,
      categoryId,
      name
    } = course;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("duration", duration);
    formData.append("level", level);
    formData.append("image", image);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("categoryId", categoryId);
    formData.append("userId", signUser?._id);

    outcome.forEach((item) => formData.append("outcome[]", item));
    prereqs.forEach((item) => formData.append("prereqs[]", item));
    target.forEach((item) => formData.append("target[]", item));

    const res = await fetch(
      `${API_URL}/api/course/addcourse`,
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await res.json();
    const courseFormError = document.getElementById("courseFormError");
    data.message !== undefined
      ? (courseFormError.innerText = data.message)
      : (courseFormError.innerText = "");

    if (res.ok) {
      allCourses();
      setCourse({
        title: "",
        name: "",
        description: "",
        duration: "",
        level: "",
        image: "",
        categoryId: "",
      });
      setOutcome([""])
      setPrereqs([""])
      setTarget([""])
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Course has been created",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onchange = (e) => {
    if (e.target.files) {
      setCourse({ ...course, image: e.target.files[0] });
    } else {
      setCourse({ ...course, [e.target.name]: e.target.value });
    }
  };

  // pagination implement
  const coursePerPage = 16;
  const lastCourseIndex = pageNumber * coursePerPage;
  const firstCourseIndex = lastCourseIndex - coursePerPage;
  const allCorse = signUser?.role === "admin" ? (
    allCourse &&
    allCourse
      .filter((course) =>
        course.title.toLowerCase().includes(searchOption.toLowerCase())
      )
      .filter((course) => course.categoryId.includes(searchCategory))
      .slice(firstCourseIndex, lastCourseIndex)
  ) : (
    allCourse.filter(course => course.userId?._id === signUser._id).filter((course) =>
      course.title.toLowerCase().includes(searchOption.toLowerCase())
    )
      .filter((course) => course.categoryId.includes(searchCategory))
      .slice(firstCourseIndex, lastCourseIndex)
  )
  console.log(allCorse);

  const paginate = (pageNmber) => {
    setPageNumber(pageNmber);
  };

  const handleChangeOutcome = (e, index) => {
    const newOutcome = [...outcome];
    newOutcome[index] = e.target.value;
    setOutcome(newOutcome);
  };
  const handleAddOutcome = () => {
    setOutcome([...outcome, '']);
  };

  const handleChangePrereq = (e, index) => {
    const newPrereqs = [...prereqs];
    newPrereqs[index] = e.target.value;
    setPrereqs(newPrereqs);
  };
  const handleAddPrereq = () => {
    setPrereqs([...prereqs, '']);
  };

  const handleChangeTarget = (e, index) => {
    const newTarget = [...target];
    newTarget[index] = e.target.value;
    setTarget(newTarget);
  };
  const handleAddTarget = () => {
    setTarget([...target, '']);
  };
  return (
    <div className="container mt-3">
      <div className="row d-flex justify-content-center">
        <div className="col-md-3 col-6 mt-2">
          <input
            type="text"
            className="form-control"
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
            placeholder="Search By Title"
          />
        </div>
        <div className="col-md-3 col-6 mt-2">
          <select
            className="form-control"
            id="course level"
            value={searchCategory}
            onChange={(e) => setSearchCategory(e.target.value)}
          >
            <option value="">Search By Category</option>
            {AllCategory &&
              AllCategory.map((course) => {
                return <option value={course._id}>{course.category}</option>;
              })}
          </select>
        </div>
        <div className="d-flex justify-content-end col-md-5 col-6 mt-2">
          <button
            className="btn btn-primary mb-5"
            onClick={handleShow}
          >
            <i className="fas fa-plus"></i> Add Course
          </button>
        </div>
        <div className="col-md-11">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th scope="col">Sr#</th>
                  <th scope="col">Title</th>
                  <th scope="col">Category</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Lesson</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allCorse &&
                  allCorse.map((course, index) => {
                    return (
                      <tr>
                        <td>{firstCourseIndex + index + 1}</td>
                        <td>{course.title}</td>
                        {courseCategory[index] && (
                          <td>{courseCategory[index].category}</td>
                        )}
                        <td>{course.duration}</td>
                        <td>
                          <AddLessonModal courseIdd={course._id} />
                        </td>
                        <td>
                          <CourseModals courseIdd={course._id} />
                          <CourseEditModals courseIdd={course._id} />
                          <i
                            className="fa fa-trash"
                            onClick={() => deleteCourse(course._id)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end my-3">
            {[...Array(Math.ceil(allCourse.length / coursePerPage)).keys()].map(
              (number) => {
                return (
                  <button
                    key={number + 1}
                    className="btn btn-primary mx-1"
                    onClick={() => paginate(number + 1)}
                  >
                    {number + 1}
                  </button>
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* {/ {/ {/ Add Lesson Modal /} /} /} */}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p id="courseFormError" className="text-danger"></p>
          <form
            className="new-add-course-form"
            onSubmit={addCourse}
            encType="multipart/form-data"
          >
            <h5 className="fw-light mb-3" style={{ textDecoration: "underline" }}>
              Course Introduction
            </h5>
            <div className="row mb-3 align-items-center">

              <div className="col-md-4">
                <label htmlFor="title" className="form-label">
                  Course Title <span className="text-danger">*</span>
                </label>
                <input
                  id="title"
                  name="title"
                  className="form-control"
                  placeholder="Give a catchy course title"
                  type="text"
                  value={course.title}
                  onChange={onchange}
                />
                <span id="error" className="text-danger"></span>
              </div>

              <div className="col-md-4">
                <label htmlFor="categoryId" className="form-label">
                  Course Category <span className="text-danger">*</span>
                </label>
                <select
                  id="categoryId"
                  className="form-control"
                  name="categoryId"
                  value={course.categoryId}
                  onChange={onchange}
                >
                  <option value="">Select Category</option>
                  {category &&
                    category.map((data) => (
                      <option key={data._id} value={data._id}>
                        {data.category}
                      </option>
                    ))}
                </select>
                <span id="error" className="text-danger"></span>
              </div>

              <div className="col-md-4">
                <label htmlFor="duration" className="form-label">
                  Course Duration <span className="text-danger">*</span>
                </label>
                <input
                  id="duration"
                  name="duration"
                  className="form-control"
                  placeholder="Your course duration"
                  type="text"
                  value={course.duration}
                  onChange={onchange}
                />
                <span id="error" className="text-danger"></span>
              </div>
            </div>

            <div className="row mb-3">

              <div className="col-md-4 mt-3">
                <label htmlFor="level" className="form-label">
                  Course Level <span className="text-danger">*</span>
                </label>
                <select
                  id="level"
                  className="form-control"
                  name="level"
                  value={course.level}
                  onChange={onchange}
                >
                  <option value="">Select Course Level</option>
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
                <span id="error" className="text-danger"></span>
              </div>

              <div className="col-md-4 mt-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Give your name for public view"
                  type="text"
                  value={course.name}
                  onChange={onchange}
                />
                <span id="error" className="text-danger"></span>
              </div>

              <div className="col-md-4 mt-3">
                <label htmlFor="image" className="form-label">
                  Featured Image
                </label>
                <input
                  id="image"
                  name="image"
                  className="form-control"
                  type="file"
                  title="Upload course featured image"
                  onChange={onchange}
                />
                <span id="error" className="text-danger"></span>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label htmlFor="description" className="form-label">
                Course Description <span className="text-danger">*</span>
              </label>
              <textarea
                id="description"
                name="description"
                className="form-control"
                placeholder="Tell a bit about your course"
                value={course.description}
                onChange={onchange}
              />
              <span id="error" className="text-danger"></span>
            </div>

            <h5 className="fw-light mb-3" style={{ textDecoration: "underline" }}>
              Intended Learners
            </h5>
            <div className="row mb-3">
              <div className="col-md-12">
                <Accordion defaultActiveKey="">
                  <Accordion.Item className="form-accord" eventKey="0">
                    <Accordion.Header>
                      What will the students learn from your course?
                    </Accordion.Header>
                    <Accordion.Body>
                      <label htmlFor="outcome" className="form-label">
                        Learning Outcome <span className="text-danger">*</span>
                      </label>
                      {outcome.map((outcomeValue, index) => (
                        <div key={index}>
                          <input
                            id={`outcome-${index}`}
                            type="text"
                            className="form-control mb-3"
                            name="outcome"
                            value={outcomeValue}
                            onChange={(e) => handleChangeOutcome(e, index)}
                          />
                        </div>
                      ))}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                        onClick={handleAddOutcome}
                      >
                        + Add more to your response
                      </span>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <Accordion defaultActiveKey="">
                  <Accordion.Item className="form-accord" eventKey="0">
                    <Accordion.Header>
                      What are the requirements or prerequisites for this course?
                    </Accordion.Header>
                    <Accordion.Body>
                      <label htmlFor="prereq" className="form-label">
                        Prerequisite
                      </label>
                      {prereqs.map((prereqValue, index) => (
                        <div key={index}>
                          <input
                            id={`prereq-${index}`}
                            className="form-control mb-3"
                            type="text"
                            value={prereqValue}
                            onChange={(e) => handleChangePrereq(e, index)}
                          />
                        </div>
                      ))}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                        onClick={handleAddPrereq}
                      >
                        + Add more to your response
                      </span>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <Accordion defaultActiveKey="">
                  <Accordion.Item className="form-accord" eventKey="0">
                    <Accordion.Header>Who is this course for?</Accordion.Header>
                    <Accordion.Body>
                      <label htmlFor="target" className="form-label">
                        Target Audience <span className="text-danger">*</span>
                      </label>
                      {target.map((targetValue, index) => (
                        <div key={index}>
                          <input
                            id={`target-${index}`}
                            className="form-control mb-3"
                            type="text"
                            value={targetValue}
                            onChange={(e) => handleChangeTarget(e, index)}
                          />
                        </div>
                      ))}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                        onClick={handleAddTarget}
                      >
                        + Add more to your response
                      </span>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            <div className="text-center">
              <button className="first-button mt-3">Add Your Course</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
