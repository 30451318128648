import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import MyContext from "../../contexts/Mycontexts";
import SchoolContext from "../../contexts/SchoolContext";
import API_URL from "../../ApuUrl";

export default function RegisterSchool() {
    const { signUser } = useContext(MyContext)
    const { allSchools } = useContext(SchoolContext)
    const [School, setSchool] = useState({
        name: "",
        email: "",
        number: "",
        city: "",
        address: "",
        detail: "",
        fpNumber: "",
        category: "",
        image: "",
        userId: ""
    })
    // add School
    const addSchool = async (e) => {
        e.preventDefault()
        const { name, email, number, city, address, detail, fpNumber, category, image } = School

        const emailError = document.getElementById("emailError")
        let emptyFieldError = false
        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            emailError.innerText = "Please enter a valid email address";
            emptyFieldError = true;
        } else {
            emailError.innerText = ""
        }
        if (emptyFieldError) {
            return;
        }

        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("category", category);
        formData.append("address", address);
        formData.append("image", image);
        formData.append("number", number);
        formData.append("fpNumber", fpNumber);
        formData.append("detail", detail);
        formData.append("city", city);
        formData.append("userId", signUser._id);

        const res = await fetch(`${API_URL}/api/school/addSchool`, {
            method: "POST",
            body: formData
        })
        const data = await res.json()
        console.log(data.message);

        const schoolFormError = document.getElementById("schoolFormError")
        data.message !== undefined ? schoolFormError.innerText = data.message : schoolFormError.innerText = ""
        if (res.ok) {
            setSchool({
                name: "",
                email: "",
                number: "",
                city: "",
                address: "",
                detail: "",
                fpNumber: "",
                category: "",
                image: ""
            })
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Your School Registration Request has been sent successfully",
                showConfirmButton: false,
                timer: 1500
            });
            allSchools()
        }
    }

    const onchange = (e) => {
        if (e.target.files) {
            setSchool({ ...School, image: e.target.files[0] })
        } else {
            setSchool({ ...School, [e.target.name]: e.target.value })
        }
    };

    return (
        <div>
            <form onSubmit={addSchool}>
                <div id="schoolFormError" className="text-danger"></div>
                <div className="row">
                    <div className="col-md-6">
                        <label className="mt-3">Name <span className="text-danger">*</span></label>
                        <input className="form-control" type="text" placeholder="Name" name="name" value={School.name} onChange={onchange} />
                        <div id="nameError" className="text-danger"></div>
                        <label className="mt-3">Contact Number <span className="text-danger">*</span></label>
                        <PhoneInput
                            className="phonInput"
                            inputStyle={{
                                border: "none",
                                boxShadow: "none"
                            }}
                            country={'pk'}
                            name="number"
                            value={School.number}
                            onChange={(value, country, event, formattedValue) => {
                                onchange({
                                    target: {
                                        name: 'number',
                                        value: formattedValue
                                    }
                                });
                            }}
                            inputProps={{
                                placeholder: "Contact Number"
                            }}
                        />
                        <div id="numberError" className="text-danger"></div>
                        <label className="mt-3">Address <span className="text-danger">*</span></label>
                        <input className="form-control" type="text" placeholder="Address" name="address" value={School.address} onChange={onchange} />
                        <div id="addressError" className="text-danger"></div>
                        <label className="mt-3">City <span className="text-danger">*</span></label>
                        <input className="form-control" type="text" placeholder="City" name="city" value={School.city} onChange={onchange} />
                        <div id="cityError" className="text-danger"></div>
                    </div>

                    <div className="col-md-6">
                        <label className="mt-3">Email <span className="text-danger">*</span></label>
                        <input className="form-control" type="email" placeholder="Email" name="email" value={School.email} onChange={onchange} />
                        <div id="emailError" className="text-danger"></div>
                        <label className="mt-3">Focal Person Number <span className="text-danger">*</span></label>
                        <PhoneInput
                            className="phonInput"
                            inputStyle={{
                                border: "none",
                                boxShadow: "none"
                            }}
                            country={'pk'}
                            name="fpNumber"
                            value={School.fpNumber}
                            onChange={(value, country, event, formattedValue) => {
                                onchange({
                                    target: {
                                        name: 'fpNumber',
                                        value: formattedValue
                                    }
                                });
                            }}
                        />
                        <div id="focalError" className="text-danger"></div>
                        <label className="mt-3">Category <span className="text-danger">*</span></label>
                        <select name="category" className="form-control" value={School.category} onChange={onchange} style={{ backgroundColor: "white" }}>
                            <option value="">Select Category</option>
                            <option value="private">Private</option>
                            <option value="public">Public</option>
                        </select>
                        <div id="categoryError" className="text-danger"></div>
                        <label className="mt-3">School Image</label>
                        <input className="form-control" type="file" placeholder="Image" name="image" onChange={onchange} />
                    </div>

                    <div className="col-md-12">
                        <label className="mt-3">Detail <span className="text-danger">*</span></label>
                        <textarea className="form-control" name="detail" id="" cols="30" rows="10" placeholder="School Detail" value={School.detail} onChange={onchange}></textarea>
                        <div id="detailError" className="text-danger"></div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" style={{ zIndex: "200" }} className="btn btn-primary mt-3 px-4 py-2">Add School</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}