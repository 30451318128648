import React, { useContext, useEffect, useState } from 'react'
import CourseContext from '../../contexts/CourseContext';
import { Modal } from 'react-bootstrap';

function CourseModals({ courseIdd }) {
  const [lgShow, setLgShow] = useState(false)
  const { getCourseCat, getCourse, CourseById } = useContext(CourseContext);

  const viewModalId = () => {
    CourseById(courseIdd)
    setLgShow(true)
  }
  return (
    <>
      {/* {/ {/ view modal /} /} */}
      <i
        className="fa fa-eye me-2"
        onClick={() => viewModalId()}
      ></i>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            View Course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="title" className="mt-3">
                  Title
                </label>
                <input
                  className="form-control"
                  value={getCourse.title}
                  id="title"
                  type="text"
                  placeholder="Title"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="Duration" className="mt-3">
                  Duration
                </label>
                <input
                  className="form-control"
                  value={getCourse.duration}
                  id="Duration"
                  type="text"
                  placeholder="Duration"
                />
                <label htmlFor="level" className="mt-3">
                  Course Level
                </label>
                <input
                  className="form-control"
                  value={getCourse.level}
                  type="text"
                  id="level"
                  placeholder="Level"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="category" className="mt-3">
                  Category
                </label>
                {getCourseCat && (
                  <input
                    className="form-control"
                    value={getCourseCat.category}
                    id="title"
                    type="text"
                    placeholder="Category"
                  />
                )}
                <label htmlFor="category" className="mt-3">
                  Image
                </label>
                <input
                  className="form-control"
                  value={getCourse.image}
                  id="title"
                  type="text"
                  placeholder="Image"
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="role" className="mt-3">
                  Course Description
                </label>
                <textarea
                  name="course description"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={getCourse.description}
                  type="text"
                  placeholder="role"
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CourseModals
