import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import "react-quill/dist/quill.snow.css";
import SubmitContext from '../../contexts/SubmitContext';
import MyContext from '../../contexts/Mycontexts';
import TaskContext from '../../contexts/TaskController';

export default function SubmittedTasks() {
    const { submitTasks, getSubmitTaskById, submitTaskById, delSubmitTask } = useContext(SubmitContext)
    const { signUser } = useContext(MyContext)
    const [taskViewShow, setTaskViewShow] = useState(false)

    const filterSubmittedTasks = signUser?.role === "admin" ? submitTasks : submitTasks?.filter(task => task.instId?._id === signUser._id)

    const formateDate = (date) => {
        return new Date(date).toLocaleDateString()
    }

    const openModalViewTask = (id) => {
        getSubmitTaskById(id)
        setTaskViewShow(true)
    }

    return (
        <>
            <div className="Container py-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-container" style={{ overflowX: "auto" }}>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr#</th>
                                        <th scope="col">Candidate</th>
                                        <th scope="col">Course Title</th>
                                        <th scope="col">Lesson Title</th>
                                        <th scope="col">Task Title</th>
                                        <th scope="col">Submit Date</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterSubmittedTasks?.map((task, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{task.userId?.name}</td>
                                            <td>{task.courseId?.title}</td>
                                            <td>{task.lessonId?.title}</td>
                                            <td>{task.taskId?.title}</td>
                                            <td>{formateDate(task.createdAt)}</td>
                                            <td><i className="fa fa-eye me-2" onClick={() => openModalViewTask(task._id)} ></i>
                                                <i className="fa fa-trash" onClick={() => delSubmitTask(task._id)}></i>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* {/ view modal /} */}
            <Modal
                size="lg"
                show={taskViewShow}
                onHide={() => setTaskViewShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        View Task Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div id="error" className="text-danger text-center"></div>
                        <label htmlFor="description">Candidate Answer</label>
                        <textarea
                            className="mb-3 form-control"
                            rows={10}
                            value={submitTaskById.description}
                        />
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}
