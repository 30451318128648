import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import InstructorContext from '../../contexts/InstructorContext';
import { Button, Modal } from 'react-bootstrap';
import CategoryContext from '../../contexts/CategoryContext';
import CourseContext from '../../contexts/CourseContext';
import approvalWait from '../../img/approval-wait.avif'
import InstructorCourse from './InstructorCourse';
import MyContext from '../../contexts/Mycontexts';
import Addcourse from '../../adminside/Course/Addcourse';

export default function InstructorAddCourse() {
    const { techerApprove, TeacherApprovedFn } = useContext(InstructorContext)

    useEffect(() => {
        TeacherApprovedFn()
    }, [])

    const noRequest = techerApprove.length === 0
    const notApproved = techerApprove.filter(teacher => teacher.status === "Not Approved" || (teacher.status === "Rejected"))
    const Approved = techerApprove.filter(teacher => teacher.status === "Approved")

    if (noRequest || notApproved.length > 0) {
        return <div className="container request-approval">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-4">
                    <img src={approvalWait} alt="wait-for-approval" className="img-fluid wiat-img" />
                </div>
                <div className="col-md-8">
                    <div className="section-header">
                        <h2>You can add courses once you're approved as instructor</h2>
                    </div>
                    <p>You will be able to manage your course after accepted request.</p>
                </div>
            </div>

        </div>
    } else if (Approved.length > 0) {
        return (
            <>
                <Addcourse />
            </>
        );
    }

}
