import React from 'react'
import GeneratedCertificate from '../../adminside/GenerateCerticate/GeneratedCertificates'

export default function StudentCertificate() {
    return (
        <>
            <GeneratedCertificate />
        </>
    )
}
