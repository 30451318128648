import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import "react-quill/dist/quill.snow.css";
import MyContext from '../../contexts/Mycontexts';
import ResultContext from '../../contexts/ResultContext';
import userContext from '../../contexts/UseContext';
import userImage from "../../img/userImage.png"

export default function AllResults() {
    const { results } = useContext(ResultContext);
    const { signUser } = useContext(MyContext);
    const { allUsers } = useContext(userContext)
    const [resultById, setResultById] = useState({});
    const [taskViewShow, setTaskViewShow] = useState(false);
    const [taskResultShow, setTaskResultShow] = useState(false);
    const [resultUser, setResultUser] = useState({});

    let totalMarks = 0;
    let obtainedMarks = 0;

    // Extract unique students
    const uniqueUsers = [];
    results?.forEach(result => {
        result.students?.forEach(student => {
            if (!uniqueUsers.find(user => user.userId?._id === student.userId?._id)) {
                uniqueUsers.push(student);
            }
        });
    });

    const filterUniqueUser = signUser?.role === "admin" ? uniqueUsers : uniqueUsers?.filter(user => user.userId?._id === signUser?._id)
    const viewGetId = (id, isView) => {
        const filterUser = allUsers?.filter(user => user._id === id);
        setResultUser(filterUser[0]);

        const filteredResults = results.filter(result =>
            result.students.some(student => student.userId?._id === id)
        );

        const groupedByCourse = {};
        filteredResults?.forEach(result => {
            const courseId = result.courseId?._id
            if (!groupedByCourse[courseId]) {
                groupedByCourse[courseId] = {
                    course: result.courseId,
                    lessons: {}
                }
            }

            const lessonId = result.lessonId?._id;
            if (!groupedByCourse[courseId].lessons[lessonId]) {
                groupedByCourse[courseId].lessons[lessonId] = {
                    lessons: result.lessonId,
                    tasks: {},
                    students: []
                }
            }

            const taskId = result.taskId?._id;
            if (!groupedByCourse[courseId].lessons[lessonId].tasks[taskId]) {
                groupedByCourse[courseId].lessons[lessonId].tasks[taskId] = {
                    tasks: result.taskId,
                }
            }

            result.students?.forEach(student => {
                if (student.userId?._id === id) {
                    groupedByCourse[courseId].lessons[lessonId].students.push(student)
                }
            })

        })
        setResultById(groupedByCourse);
        if (isView) {
            setTaskViewShow(true);
        } else {
            setTaskResultShow(true);
        }
    };

    return (
        <>
            <div className="Container py-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-container" style={{ overflowX: "auto" }}>
                            <p className='m-0' style={{ fontWeight: "regular", color: "gray" }}>Results List</p>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr#</th>
                                        <th scope="col">Candidate</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterUniqueUser?.map((student, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{student.userId?.name}</td>
                                            <td>{student.userId?.email}</td>
                                            <td>
                                                {signUser?.role === "admin" && (
                                                    <i className="fa fa-eye me-3" onClick={() => viewGetId(student.userId?._id, true)}></i>
                                                )}
                                                <i className="fa fa-clipboard-list" onClick={() => viewGetId(student.userId?._id, false)}></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* View Modal */}
            <Modal
                size="xl"
                show={taskViewShow}
                onHide={() => setTaskViewShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Results List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container" style={{ border: "1px solid #e8e8e8" }}>
                        {resultUser && (
                            <div className='d-flex justify-content-between pt-4'>
                                <div>
                                    <p>
                                        Name : <span style={{ fontWeight: "bold" }}> {resultUser.name}</span>
                                    </p>
                                    <p>
                                        Email : <span style={{ fontWeight: "bold" }}> {resultUser.email}</span>
                                    </p>
                                    <p>
                                        Contact Number : <span style={{ fontWeight: "bold" }}> {resultUser.number}</span>
                                    </p>
                                </div>
                                {resultUser.image ? <img src={resultUser.image} style={{ width: "10%" }} className='img-fluid' alt="" /> : <img src={userImage} style={{ width: "10%" }} className='img-fluid' alt="" />}
                            </div>
                        )}
                        {Object.values(resultById).map((courseData, index) => {
                            totalMarks = 0;
                            obtainedMarks = 0;
                            return <div>
                                <div className='d-flex justify-content-center'>
                                    <p className='p-3' style={{ border: "1px solid rgba(246, 145, 51, 1)", borderRadius: "8px", display: "inline-block" }}>
                                        Course Title : <span style={{ fontWeight: "bold" }}> {courseData.course?.title}</span>
                                    </p>
                                </div>
                                <table className='table table-striped table-bordered table-responsive mb-5'>
                                    <thead>
                                        <tr>
                                            <th scope="col">Lesson Title</th>
                                            <th scope="col">Task Title</th>
                                            <th scope="col">Submit Task Description</th>
                                            <th scope="col">Total Marks</th>
                                            <th scope="col">Obtained Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(courseData.lessons).map((lessonData, index) => (
                                            Object.values(lessonData.tasks).map((taskData, taskIndex) => (
                                                lessonData.students.map((student, studentIndex) => {
                                                    totalMarks += taskData.tasks?.totalMarks
                                                    obtainedMarks += student.obtainedMarks
                                                    return <tr key={studentIndex}>
                                                        <td>{lessonData.lessons?.title}</td>
                                                        <td>{taskData.tasks?.title}</td>
                                                        <td>{student.submitTaskId?.description}</td>
                                                        <td>{taskData.tasks?.totalMarks}</td>
                                                        <td>{student.obtainedMarks}</td>
                                                    </tr>
                                                })
                                            ))
                                        ))}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>{totalMarks}</strong></td>
                                            <td><strong>{obtainedMarks}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        })}
                    </div>
                </Modal.Body>
            </Modal>
            {/* result card */}
            <Modal
                size="xl"
                show={taskResultShow}
                onHide={() => setTaskResultShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Results Card
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container" style={{ border: "1px solid #e8e8e8" }}>
                        {resultUser && (
                            <div className='d-flex justify-content-between pt-4'>
                                <div>
                                    <p>
                                        Name : <span style={{ fontWeight: "bold" }}> {resultUser.name}</span>
                                    </p>
                                    <p>
                                        Email : <span style={{ fontWeight: "bold" }}> {resultUser.email}</span>
                                    </p>
                                    <p>
                                        Contact Number : <span style={{ fontWeight: "bold" }}> {resultUser.number}</span>
                                    </p>
                                </div>
                                {resultUser.image ? <img src={resultUser.image} style={{ width: "10%" }} className='img-fluid' alt="" /> : <img src={userImage} style={{ width: "10%" }} className='img-fluid' alt="" />}
                            </div>
                        )}
                        {Object.values(resultById).map((courseData, index) => {
                            totalMarks = 0;
                            obtainedMarks = 0;
                            return <div key={index}>
                                <div className='d-flex justify-content-center'>
                                    <p className='p-3' style={{ border: "1px solid rgba(246, 145, 51, 1)", borderRadius: "8px", display: "inline-block" }}>
                                        Course Title : <span style={{ fontWeight: "bold" }}> {courseData.course?.title}</span>
                                    </p>
                                </div>
                                <table className='table table-striped table-bordered table-responsive mb-5'>
                                    <thead>
                                        <tr>
                                            <th scope="col">Lesson Title</th>
                                            <th scope="col">Task Title</th>
                                            <th scope="col">Submit Task Description</th>
                                            <th scope="col">Total Marks</th>
                                            <th scope="col">Obtained Marks</th>
                                            <th scope="col">Percentage</th>
                                            <th scope="col">Grade</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.values(courseData.lessons).map((lessonData, index) => {
                                            return Object.values(lessonData.tasks).map((taskData, taskIndex) => {
                                                return lessonData.students.map((student, studentIndex) => {
                                                    totalMarks += taskData.tasks?.totalMarks;
                                                    obtainedMarks += student.obtainedMarks;
                                                    const percentage = (student.obtainedMarks / taskData.tasks?.totalMarks) * 100;
                                                    let grade = '';
                                                    if (percentage >= 95) {
                                                        grade = 'A+';
                                                    } else if (percentage >= 85) {
                                                        grade = 'A';
                                                    } else if (percentage >= 80) {
                                                        grade = 'B';
                                                    } else if (percentage >= 70) {
                                                        grade = 'C';
                                                    } else if (percentage >= 50) {
                                                        grade = 'D';
                                                    } else {
                                                        grade = 'F';
                                                    }
                                                    return <tr key={studentIndex}>
                                                        <td>{lessonData.lessons?.title}</td>
                                                        <td>{taskData.tasks?.title}</td>
                                                        <td>{student.submitTaskId?.description}</td>
                                                        <td>{taskData.tasks?.totalMarks}</td>
                                                        <td>{student.obtainedMarks}</td>
                                                        <td>{percentage}%</td>
                                                        <td>{grade}</td>
                                                    </tr>
                                                });
                                            })

                                        })}
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><strong>{totalMarks}</strong></td>
                                            <td><strong>{obtainedMarks}</strong></td>
                                            <td> <strong> {obtainedMarks / totalMarks * 100}%</strong></td>
                                            <td>
                                                <strong>
                                                    {(() => {
                                                        const finalPercentage = (obtainedMarks / totalMarks) * 100;
                                                        if (finalPercentage >= 95) return 'A+';
                                                        if (finalPercentage >= 85) return 'A';
                                                        if (finalPercentage >= 80) return 'B';
                                                        if (finalPercentage >= 70) return 'C';
                                                        if (finalPercentage >= 50) return 'D';
                                                        return 'F';
                                                    })()}
                                                </strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        })}
                    </div>
                </Modal.Body>
            </Modal >

        </>
    );
}
