import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import GenContext from '../../contexts/GenCertiContext'
import userContext from '../../contexts/UseContext'
import mentorsLogo from "../../img/mentors-logo.png"
import userImg from "../../img/userImage.png"
import badgeImg from "../../img/certified-badge.png"
import ConfigContext from '../../contexts/ConfigCerti'
import MyContext from '../../contexts/Mycontexts'

export default function GeneratedCertificate() {
    const { genCerti } = useContext(GenContext)
    const { configCerti } = useContext(ConfigContext)
    const { allUsers } = useContext(userContext)
    const { signUser } = useContext(MyContext)
    const [viewCertificate, setViewCertificate] = useState(false)
    const [certById, setCertById] = useState(null)
    const [candiById, setCandiById] = useState(null)

    const viewGetId = (candId, corsId) => {
        setViewCertificate(true)
        const generateCertById = configCerti?.filter(certi => certi.courseId?._id === corsId)
        setCertById(generateCertById[0])
        const candidateData = allUsers?.filter(user => user._id === candId)
        setCandiById(candidateData[0])
    }

    const formateDate = (date) => {
        return new Date(date).toLocaleDateString()
    }

    const filterGeneratedCerti = signUser?.role === "admin"
        ? genCerti
        : genCerti?.filter(certi => certi.candidateId?.some(cand => cand._id === signUser._id))
            .map(certi => ({
                ...certi, candidateId: certi.candidateId.filter(cand => cand._id === signUser?._id)
            }))

    return (
        <>
            <div className="mt-3">
                <div className="table-container" style={{ overflowX: "auto" }}>
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">Sr#</th>
                                <th scope="col">Generated Date</th>
                                <th scope="col">Candidate Name</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterGeneratedCerti?.map(certi => (
                                certi.candidateId?.map((genCert, ind) => (
                                    <tr key={ind}>
                                        <td>{ind + 1}</td>
                                        <td>{formateDate(certi.generateDate)}</td>
                                        <td>{genCert.name}</td>
                                        <td>
                                            <i className="fa fa-eye me-2" onClick={() => viewGetId(genCert._id, certi.courseId?._id)}></i>
                                        </td>
                                    </tr>
                                ))
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* generated certificate */}
            <Modal
                size="xl"
                show={viewCertificate}
                onHide={() => setViewCertificate(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Student Certificate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 pt-3" style={{ border: "5px solid #A9A9A9" }}>
                                <div className="d-flex justify-content-center">
                                    {candiById?.image ? (
                                        <img src={candiById?.image} className='form-control mb-3' style={{ width: "20%", borderRadius: "50%" }} alt="" />
                                    ) : (
                                        <img src={userImg} className='form-control mb-3' style={{ width: "20%", borderRadius: "50%" }} alt="" />
                                    )}
                                </div>
                                <h3 className='text-center mb-3'>{candiById?.name}</h3>
                                <p className='text-center mb-4'>This certificate is proudly awarded to <span style={{ fontWeight: "bold" }}> {candiById?.name} </span> in recognition of the successful completion of <span style={{ fontWeight: "bold" }}>{certById?.courseId?.title}</span>. Your dedication and commitment to learning demonstrate your pursuit of excellence and professional growth. We wish you all the best for future endeavors.</p>
                                {/* <p><span className='fw-bold'>Issue Date:</span> </p> */}
                                <div className="d-flex align-items-center justify-content-between mt-5">
                                    <p className='text-center'>
                                        _______________<br />
                                        Date
                                    </p>
                                    <img src={badgeImg} className='form-control' style={{ width: "145px", borderRadius: "100%" }} alt="" />
                                    <p className='text-center'>
                                        _______________<br />
                                        Signature
                                    </p>
                                </div>
                                {/* <div className="d-flex justify-content-between mt-4"> */}
                                {/* <img src={mentorsLogo} className='img-fluid' style={{ width: "15%" }} alt="" /> */}
                                <p className='text-center mt-4'> <a style={{ color: "initial" }} href='https://mentorsacademia.com/' target='blank'>MentorsAcademia.com</a></p>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}
