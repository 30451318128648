import { useContext, useEffect, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import Swal from "sweetalert2"
import userContext from "../contexts/UseContext"
import MyContext from "../contexts/Mycontexts"

function UserProfile() {
    const { signUser, setSignUser } = useContext(MyContext)
    const [updateType, setUpdateType] = useState("password")

    const [updateSignUser, setUpdateSignUser] = useState({
        name: "",
        email: "",
        number: "",
        password: ""
    });

    useEffect(() => {
        if (signUser) {
            setUpdateSignUser({
                name: signUser.name || "",
                email: signUser.email || "",
                number: signUser.number || "",
                password: signUser.password || ""
            });
        }
    }, [signUser]);
    const changeType = () => {
        updateType === "text" ? setUpdateType("password") : setUpdateType("text")
    }

    const updateUser = async (e) => {
        e.preventDefault()
        const { name, number, image } = updateSignUser
        const formData = new FormData()
        formData.append("name", name)
        formData.append("number", number)
        formData.append("image", image)

        const res = await fetch(`https://accademia-backend.vercel.app/api/user/updateUser/${signUser._id}`, {
            method: "PUT",
            body: formData
        })
        const data = await res.json()

        if (res.ok) {
            Swal.fire("Changes Saved!");
            sessionStorage.setItem("userData", JSON.stringify(data));
            setSignUser(data)
        }
    }

    const formValueChange = (e) => {
        if (e.target.files) {
            setUpdateSignUser({ ...updateSignUser, image: e.target.files[0] })
        } else {
            setUpdateSignUser({ ...updateSignUser, [e.target.name]: e.target.value })
        }
    }

    return (
        <>
            <section className="interview-section">
                <Container>
                    <Row className="mt-3 justify-content-center align-items-center">
                        <Col className="" md={12}>
                            <Form className="admin-form pb-4" onSubmit={updateUser}>
                                <Row>
                                    <Col md={6}>
                                        <label className="mt-3 mb-2">Name</label>
                                        <Form.Control className="p-3" name="name" value={updateSignUser.name} onChange={formValueChange}></Form.Control>
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-3 mb-2">Email</label>
                                        <Form.Control className="p-3" value={updateSignUser.email}></Form.Control>
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-3 mb-2">Contact</label>
                                        <Form.Control className="p-3" name="number" type="number" value={updateSignUser.number} onChange={formValueChange}></Form.Control>
                                    </Col>
                                    <Col md={6}>
                                        <label className="mt-3 mb-2">Current Password</label>
                                        <div className="d-flex align-items-center p-2" style={{ border: "1px solid #ccc", borderRadius: '5px' }}>
                                            <Form.Control type={updateType} className="" value={updateSignUser.password} aria-label="Current Password" style={{ border: "none" }}></Form.Control>
                                            <i className="fas fa-eye" onClick={() => changeType()}></i>
                                        </div>
                                    </Col>
                                    <Col md={12} className="d-flex justify-content-between align-items-center">
                                        <div style={{ width: "80%" }}>
                                            <label className="mt-3 mb-2">User Image</label>
                                            <Form.Control className="p-3" name="image" type="file" onChange={formValueChange}></Form.Control>
                                        </div>
                                        {signUser?.image && (
                                            <div>
                                                <img src={signUser.image} style={{ width: "100px" }} alt="" />
                                            </div>
                                        )}
                                    </Col>
                                    <div className="mt-3 text-center">
                                        <Button type="submit">Save Changes</Button>
                                    </div>
                                </Row>
                            </Form>

                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default UserProfile
