import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../contexts/Mycontexts";
import Swal from "sweetalert2";
import approvalWait from '../../img/approval-wait.avif'
import InstructorContext from "../../contexts/InstructorContext";
import { Modal } from "react-bootstrap";
import API_URL from "../../ApuUrl";

export default function InstructorAproved() {
    const { allTeachers, deleteTeacher, techerApprove, teacherById, TeacherApprovedFn, TeacherByIdFn, setTeacherById } = useContext(InstructorContext)
    const [lgShow, setLgShow] = useState(false);
    const [EditTeacher, setEditTeacher] = useState([])

    // edit Teacher data  

    const updateTeacher = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });

        if (isConfirmed) {
            const { name, number, qualification, experience, image, description, website, youtube, instaUrl, fbUrl, twitterUrl, experties } = teacherById

            const formData = new FormData();
            formData.append("name", name);
            formData.append("qualification", qualification);
            formData.append("experience", experience);
            formData.append("image", image);
            formData.append("number", number);
            formData.append("experties", experties);
            formData.append("description", description);
            formData.append("website", website);
            formData.append("youtube", youtube);
            formData.append("fbUrl", fbUrl);
            formData.append("instaUrl", instaUrl);
            formData.append("twitterUrl", twitterUrl);

            const res = await fetch(`${API_URL}/api/teacher/updateTeacher/${teacherById._id}`, {
                method: "PUT",
                body: formData
            })
            const data = await res.json()
            Swal.fire("Saved!", "", "success");
            TeacherApprovedFn()
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }

    }

    const onchnge = (e) => {
        if (e.target.name === 'number') {
            if (!/^\d*$/.test(e.target.value)) {
                setTeacherById((School) => ({ ...School, [e.target.name]: e.target.value.replace(/\D/g, '') }));
                return;
            }
        }
        if (e.target.files) {
            setTeacherById({ ...teacherById, image: e.target.files[0] })
        } else {
            setTeacherById({ ...teacherById, [e.target.name]: e.target.value })
        }
    }

    useEffect(() => {
        TeacherApprovedFn()
    }, [])

    const openModalEditTeacher = (id) => {
        TeacherByIdFn(id)
        setLgShow(true)
    }

    const noRequest = techerApprove.length === 0
    const notApproved = techerApprove.filter(teacher => teacher.status === "Not Approved" || (teacher.status === "Rejected"))
    const Approved = techerApprove.filter(teacher => teacher.status === "Approved")
    if (noRequest) {
        return <div className="container request-approval">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-4">
                    <img src={approvalWait} alt="wait-for-approval" className="img-fluid wiat-img" />
                </div>
                <div className="col-md-8">
                    <div className="section-header">
                        <h2>Hmm..!! You Can See Your Details After Sending Registration Request</h2>
                    </div>
                    <p>You will be able to see your details as soon as you send Your request!</p>
                </div>
            </div>

        </div>
    } else if (notApproved.length > 0) {
        return <div>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Number</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {techerApprove.map((teacher) => {
                        return <tr>
                            <td>{teacher.name}</td>
                            <td>{teacher.email}</td>
                            <td>{teacher.number}</td>
                            <td>{teacher.status}</td>
                            <td>
                                <i className="fa fa-trash" onClick={() => deleteTeacher(teacher._id)}></i>
                            </td>
                        </tr>
                    })}

                </tbody>
            </table>
        </div>
    } else if (Approved.length > 0) {
        return <div>
            <div className="container">
                <div className="row d-flex">
                    <div className="col-md-6">
                        <div className="section-header pt-3">
                            <h2>Welcome To The Platform!</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-container" style={{ overflowX: "auto" }}>
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {techerApprove && techerApprove.filter(teacher => teacher.status === "Approved").map((teacher) => {

                            return <tr>
                                <td>{teacher.name}</td>
                                <td>{teacher.email}</td>
                                <td>{teacher.number}</td>
                                <td>{teacher.status}</td>
                                <td> <i className="fa fa-pen me-2" onClick={() => openModalEditTeacher(teacher._id)}></i></td>
                            </tr>
                        })}

                    </tbody>
                </table>
            </div>


            {/* Edit Teacher modal */}
            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Edit Teacher</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">


                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Large Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={updateTeacher}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="name">Name</label>
                                    <input className="form-control" type="text" placeholder="Name" name="name" value={teacherById.name} onChange={onchnge} />
                                    <div id="nameError" className="text-danger"></div>
                                    <label htmlFor="experties" className="mt-3">Experties</label>
                                    <input className="form-control" type="text" placeholder="Experties" name="experties" value={teacherById.experties} onChange={onchnge} />
                                    <label htmlFor="number" className="mt-3">Contact Number</label>
                                    <input className="form-control" type="tel" placeholder="Number" name="number" value={teacherById.number} onChange={onchnge} />
                                    <div id="numberError" className="text-danger"></div>
                                    <label htmlFor="youtube" className="mt-3">Youtube Link</label>
                                    <input className="form-control" type="link" placeholder="Youtube Link" name="youtube" value={teacherById.youtube} onChange={onchnge} />
                                    <label htmlFor="experience" className="mt-3">Fb Profile Link</label>
                                    <input className="form-control" type="link" placeholder="Fb Profile Link" name="fbUrl" value={teacherById.fbUrl} onChange={onchnge} />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="email">Email</label>
                                    <input className="form-control" type="email" placeholder="Email" name="email" value={teacherById.email} />
                                    <div id="emailError" className="text-danger"></div>
                                    <label htmlFor="website" className="mt-3">Website URL</label>
                                    <input className="form-control" type="text" placeholder="Website Url" name="website" value={teacherById.website} onChange={onchnge} />
                                    <label htmlFor="image" className="mt-3">Profile Image</label>
                                    <input className="form-control" type="file" placeholder="Website Url" name="image" onChange={onchnge} />
                                    <label htmlFor="experience" className="mt-3">Twitter Profile Link</label>
                                    <input className="form-control" type="link" placeholder="twitter Profile Link" name="twitterUrl" value={teacherById.twitterUrl} onChange={onchnge} />
                                    <label htmlFor="experience" className="mt-3">Insta Profile Link</label>
                                    <input className="form-control" type="link" placeholder="Insta Profile Link" name="instaUrl" value={teacherById.instaUrl} onChange={onchnge} />
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="qualification" className="mt-3">Qualification</label>
                                    <textarea rows={10} className="form-control" type="text" placeholder="Qualifications" name="qualification" value={teacherById.qualification} onChange={onchnge} />
                                    <div id="qualiError" className="text-danger"></div>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="experience" className="mt-3">Experience</label>
                                    <textarea rows={10} className="form-control" type="text" placeholder="Experience" name="experience" value={teacherById.experience} onChange={onchnge} />
                                    <div id="expeError" className="text-danger"></div>
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="description" className="mt-3">Description</label>
                                    <textarea className="form-control" name="description" id="" cols="30" rows="10" placeholder="Description" value={teacherById.description} onChange={onchnge}></textarea>
                                    <div id="descriptionError" className="text-danger"></div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button type="submit" className="btn btn-primary mt-3 px-3" onClick={updateTeacher}>Update teacher</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    }

}