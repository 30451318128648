import React, { useState } from 'react'
import { useContext } from 'react'
import CourseContext from '../../contexts/CourseContext'
import LessonContext from '../../contexts/LessonContext'
import TaskContext from '../../contexts/TaskController'
import SubmitContext from '../../contexts/SubmitContext'
import Swal from 'sweetalert2'
import ResultContext from '../../contexts/ResultContext'
import API_URL from '../../ApuUrl'

export default function Result() {
    const { allCourse } = useContext(CourseContext)
    const { lessons } = useContext(LessonContext)
    const { tasks } = useContext(TaskContext)
    const { submitTasks } = useContext(SubmitContext)
    const { uploadedResults, results } = useContext(ResultContext)
    const [courseId, setCourseId] = useState("")
    const [lessonId, setLessonId] = useState("")
    const [taskId, setTaskId] = useState("")
    const [taskUsers, setTaskUsers] = useState([])
    const [obtainedMarks, setObtainedMarks] = useState({});

    const courseLessons = lessons?.filter(less => less.courseId?._id === courseId)
    const lessonTasks = tasks?.filter(task => task.lessonId?._id === lessonId)
    const filterUsers = () => {
        const cubmitTaskUser = submitTasks?.filter(submit => submit.taskId?._id === taskId)
        setTaskUsers(cubmitTaskUser)
    }

    const existingResult = results?.filter(result => result.taskId?._id === taskId)

    const resetFilter = () => {
        setTaskUsers([])
        setLessonId("")
        setTaskId("")
        setCourseId("")
    }

    const uploadResult = async (e) => {
        e.preventDefault();

        const resultData = taskUsers.map(user => {
            const existingMark = existingResult?.find(result => result.students.find(student => student.userId?._id === user.userId?._id))
            const obtainMarks = obtainedMarks[user.userId?._id] !== undefined ? obtainedMarks[user.userId?._id] : existingMark?.students.find(student => student.userId?._id === user.userId?._id)?.obtainedMarks
            return {
                userId: user.userId._id,
                submitTaskId: user._id,
                obtainedMarks: obtainMarks || [],
            }
        });

        const res = await fetch(`${API_URL}/api/result/uploadResult`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ courseId, lessonId, taskId, results: resultData }),
        });
        // const resultError = document.getElementById("resultError")
        // data.message !== undefined ? resultError.innerText = data.message : resultError.innerText = ""
        if (res.ok) {
            setObtainedMarks({});
            Swal.fire({
                position: "center",
                icon: "success",
                title: "Marks have been added",
                showConfirmButton: false,
                timer: 1500
            });
            resetFilter();
            uploadedResults();
        }
    };
    const handleMarksChange = (userId, value) => {
        setObtainedMarks((prevMarks) => ({
            ...prevMarks,
            [userId]: value,
        }));
    };

    return (
        <>
            {/* filters */}
            <div className="container mt-4">
                <div className="row">
                    <h5>Result List</h5>
                    <div className="col-md-3">
                        <select onChange={(e) => setCourseId(e.target.value)} className="form-control" required>
                            <option value="">Select Course</option>
                            {allCourse?.map((course) => (
                                <option key={course._id} value={course._id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select
                            onChange={(e) => setLessonId(e.target.value)}
                            className="form-control"
                            disabled={courseId === ""}
                        >
                            <option value="">Select Lesson</option>
                            {courseLessons?.map((lesson) => (
                                <option key={lesson._id} value={lesson._id}>
                                    {lesson.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select
                            onChange={(e) => setTaskId(e.target.value)}
                            className="form-control"
                            disabled={lessonId === ""}
                        >
                            <option value="">Select Task</option>
                            {lessonTasks?.map((task) => (
                                <option key={task._id} value={task._id}>
                                    {task.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="d-flex mt-4">
                        <button className="btn btn-success px-5 me-4" onClick={filterUsers}>
                            Load
                        </button>
                        <button className="btn btn-danger px-5" onClick={resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                {/* table */}
                {taskUsers?.length > 0 && (
                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="table-container" style={{ overflowX: "auto" }}>
                                <table className="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th scope="col">Candidate</th>
                                            <th scope="col">Marks</th>
                                            <th scope="col">Obtain Marks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {taskUsers?.map((taskUser, index) => {
                                            const userResult = existingResult.find(result =>
                                                result.students.find(student => student.userId?._id === taskUser.userId._id)
                                            );
                                            const student = userResult?.students.find(student => student.userId._id === taskUser.userId._id);

                                            return (
                                                <tr key={index}>
                                                    <td>{taskUser.userId?.name}</td>
                                                    <td>{taskUser.taskId?.totalMarks}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            name="obtainedMarks"
                                                            value={student ? student.obtainedMarks : obtainedMarks[taskUser.userId._id] || ""}
                                                            className="form-control"
                                                            placeholder="Enter Marks"
                                                            onChange={(e) => handleMarksChange(taskUser.userId._id, e.target.value)}
                                                        />

                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-center">
                                    <button className='btn btn-primary' onClick={uploadResult}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
