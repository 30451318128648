import React, { useContext, useState } from "react";
import MyContext from "../../contexts/Mycontexts";
import Swal from "sweetalert2";
import SchoolContext from "../../contexts/SchoolContext";
import { Modal } from "react-bootstrap";
import API_URL from "../../ApuUrl";

export default function SchoolRegistrationDetails() {
    const { signUser } = useContext(MyContext)
    const [lgShow, setLgShow] = useState("")
    const { deleteSchool, allSchool, allSchools, schoolId, getSchool, setGetSchool } = useContext(SchoolContext)

    const updateSchool = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });

        if (isConfirmed) {
            const { name, number, city, address, detail, fpNumber, category, image } = getSchool

            const formData = new FormData();
            formData.append("name", name);
            formData.append("category", category);
            formData.append("address", address);
            formData.append("image", image);
            formData.append("number", number);
            formData.append("fpNumber", fpNumber);
            formData.append("detail", detail);
            formData.append("city", city);

            await fetch(`${API_URL}/api/school/updateSchool/${getSchool._id}`, {
                method: "PUT",
                body: formData
            })
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }
        allSchools()
    }
    const onchnge = (e) => {
        if (e.target.files) {
            setGetSchool({ ...getSchool, image: e.target.files[0] })
        } else {
            setGetSchool({ ...getSchool, [e.target.name]: e.target.value })
        }
    }

    const openModalEditSchool = (id) => {
        schoolId(id)
        setLgShow(true)
    }
    return (
        <div>
            <div className="row d-flex justify-content-center">
                <div className="section-header text-center mb-3 mt-3" style={{ textDecoration: 'underline', color: '#f3f6fb' }}>
                    <h2>Your Added Schools</h2>
                </div>
                <div className="col-md-11">
                    <div className="table-container" style={{ overflowX: "auto" }}>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allSchool && allSchool.filter(school => school.userId === signUser._id).map((school) => {
                                    return <tr>
                                        <td>{school.name}</td>
                                        <td>{school.city}</td>
                                        <td>{school.address}</td>
                                        <td>{school.status}</td>
                                        <td>
                                            <i className="fa fa-pen me-2" onClick={() => openModalEditSchool(school._id)}></i>
                                            <i className="fa fa-trash" onClick={() => deleteSchool(school._id)}></i>
                                        </td>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        School Edit Modal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="name" className="mt-3">Name</label>
                                <input className="form-control" type="text" placeholder="Name" name="name" value={getSchool.name} onChange={onchnge} />
                                <label htmlFor="number" className="mt-3">Number</label>
                                <input className="form-control" type="tel" placeholder="Number" name="number" value={getSchool.number} onChange={onchnge} />
                                <label htmlFor="address" className="mt-3">Address</label>
                                <input className="form-control" type="text" placeholder="Address" name="address" value={getSchool.address} onChange={onchnge} />
                                <label htmlFor="fpNumber" className="mt-3">Focal Person Number</label>
                                <input className="form-control" type="tel" placeholder="Focal Person Number" name="fpNumber" value={getSchool.fpNumber} onChange={onchnge} />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className="mt-3">Email</label>
                                <input className="form-control" type="email" placeholder="Email" name="email" value={getSchool.email} />
                                <label htmlFor="city" className="mt-3">City</label>
                                <input className="form-control" type="text" placeholder="City" name="city" value={getSchool.city} onChange={onchnge} />
                                <label htmlFor="category" className="mt-3">Category</label>
                                <select name="category" className="form-control" value={getSchool.category} onChange={onchnge}>
                                    <option value="">Select Category</option>
                                    <option value="private">Private</option>
                                    <option value="public">Public</option>
                                </select>
                                <label htmlFor="image" className="mt-3">School Image</label>
                                <input className="form-control" type="file" placeholder="Image" name="image" onChange={onchnge} />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="website" className="mt-3">Course Detail</label>
                                <textarea className="form-control" name="detail" id="website" cols="30" rows="10" value={getSchool.detail} onChange={onchnge}></textarea>
                                <div className="d-flex justify-content-center mt-3 mb-2">
                                    <button className="btn btn-primary" onClick={updateSchool}>Update</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}
