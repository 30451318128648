import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import ConfigContext from './ConfigCerti'
import API_URL from '../ApuUrl'

function ConfigCertiProvider({ children }) {
    const [lessonCourseId, setLessonCourseId] = useState("")
    const [configCerti, setConfigCerti] = useState([])
    const [confiCertiById, setConfiCertiById] = useState([])

    const uploadedConfigCerti = async () => {
        const res = await fetch(`${API_URL}/api/configCerti/getConfCerti`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setConfigCerti(data)
    }
    const getConfiCertiById = async (id) => {
        const res = await fetch(`${API_URL}/api/configCerti/getConfCertiById/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setConfiCertiById(data)
    }
    const delConfiCerti = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your course has been deleted successfully.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(`${API_URL}/api/configCerti/delConfCerti/${id}`,
                {
                    method: "DELETE",
                }
            );
            uploadedConfigCerti();
        }
    }

    useEffect(() => {
        uploadedConfigCerti();
    }, [])


    return (
        <ConfigContext.Provider value={{ lessonCourseId, setLessonCourseId, getConfiCertiById, confiCertiById, configCerti, delConfiCerti, uploadedConfigCerti, setConfiCertiById }}>
            {children}
        </ConfigContext.Provider>
    )
}

export default ConfigCertiProvider
