import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import TaskContext from './TaskController'
import API_URL from '../ApuUrl'

function TasksProvider({ children }) {
    const [lessonCourseId, setLessonCourseId] = useState("")
    const [tasks, setTasks] = useState([])
    const [taskById, setTaskById] = useState([])

    const assignedTasks = async () => {
        const res = await fetch(`${API_URL}/api/task/getTasks`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setTasks(data)
    }
    const getTaskById = async (id) => {
        const res = await fetch(`${API_URL}/api/task/getTask/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setTaskById(data)
    }
    const delTaskById = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your course has been deleted successfully.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(
                `${API_URL}/api/task/delTask/${id}`,
                {
                    method: "DELETE",
                }
            );
            assignedTasks();
        }
    }

    useEffect(() => {
        assignedTasks();
    }, [])


    return (
        <TaskContext.Provider value={{ lessonCourseId, setLessonCourseId, tasks, getTaskById, taskById, assignedTasks, delTaskById, setTaskById }}>
            {children}
        </TaskContext.Provider>
    )
}

export default TasksProvider
