import React, { useEffect, useState } from 'react'
import API_URL from '../ApuUrl'
import GenContext from './GenCertiContext'

function GenCertiProvider({ children }) {
    const [lessonCourseId, setLessonCourseId] = useState("")
    const [genCerti, setGenCerti] = useState([])
    const [confiCertiById, setConfiCertiById] = useState([])

    const GeneratedCertificates = async () => {
        const res = await fetch(`${API_URL}/api/genCerti/generatedCertificate`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setGenCerti(data)
    }
    const getConfiCertiById = async (id) => {
        const res = await fetch(`${API_URL}/api/configCerti/getConfCertiById/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setConfiCertiById(data)
    }

    useEffect(() => {
        GeneratedCertificates();
    }, [])


    return (
        <GenContext.Provider value={{ lessonCourseId, setLessonCourseId, getConfiCertiById, confiCertiById, genCerti, GeneratedCertificates, setConfiCertiById }}>
            {children}
        </GenContext.Provider>
    )
}

export default GenCertiProvider
