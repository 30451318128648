import React, { useContext, useEffect, useState } from "react";
import LessonAccord from "./LessonAccord";
import { useParams } from "react-router-dom";
import { Accordion, Modal } from "react-bootstrap";
import TaskContext from "../../contexts/TaskController";
import MyContext from "../../contexts/Mycontexts";
import Swal from "sweetalert2";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import API_URL from "../../ApuUrl";

function EnrolledCoursePage() {
  const { title } = useParams();
  const { signUser } = useContext(MyContext)

  const pureTitle = title.replace(/-/g, " ");
  const [course, setCourse] = useState([]);
  const [enrollId, setEnrollId] = useState("");
  const [unlockedLessons, setUnlockedLessons] = useState([0]);
  const { tasks } = useContext(TaskContext)
  const [taskId, setTaskId] = useState("")
  const [instId, setInstId] = useState("")
  const [submitDesc, setSubmitDesc] = useState("")
  const [taskModal, setTaskModal] = useState(false)
  const filterTask = tasks?.filter(task => task.lessonId?._id === enrollId._id)
  const [category, setCategory] = useState("");
  const [lessonCheck, setLessonCheck] = useState([])
  const [progressBar, setProgressBar] = useState([])

  useEffect(() => {
    const savedLessons = JSON.parse(localStorage.getItem("lessonChecked")) || {}
    const courseLessons = course.lessons?.map((_, index) => savedLessons[index] || false) || []
    setLessonCheck(courseLessons)

    const selectedLessons = courseLessons?.filter(Boolean).length
    setProgressBar((selectedLessons / course.lessons?.length) * 100)
  }, [course.lessons])

  const handleCheckChange = (index) => {
    setLessonCheck((prev) => {
      const newLessonCheck = [...prev]
      newLessonCheck[index] = !newLessonCheck[index]
      const savedLessons = JSON.parse(localStorage.getItem("lessonChecked")) || {}
      savedLessons[index] = newLessonCheck[index]
      localStorage.setItem("lessonChecked", JSON.stringify(savedLessons))
      const selectedLessons = newLessonCheck?.filter(Boolean).length
      setProgressBar((selectedLessons / course.lessons?.length) * 100)
      return newLessonCheck
    })
  }

  const getCourse = async () => {
    const res = await fetch(
      `${API_URL}/api/course/getcorse/${pureTitle}`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    const lessons = data.courseTitle?.lessons || [];

    const enabledLessons = lessons.filter(lesson => lesson.status === "Y");

    if (enabledLessons?.length > 0) {
      setEnrollId(enabledLessons[0]);
    }
    setCategory(data.categoryy);
    setCourse(data.courseTitle);
  };

  useEffect(() => {
    const unlockLesson = JSON.parse(localStorage.getItem("unlockLessons"))
    if (unlockLesson) {
      setUnlockedLessons(unlockLesson)
    }
    getCourse();
  }, []);

  const iFrameUrl = (urls) => {
    if (urls.startsWith("https://www.youtube.com/embed")) {
      return urls;
    } else if (urls.includes("https://youtu.be/")) {
      const videoUrls = urls.split("https://youtu.be/");
      return `https://www.youtube.com/embed/${videoUrls[1]}`;
    } else if (urls.includes("https://www.youtube.com/watch?v")) {
      const videoUrls = urls.split("v=")[1].split("&")[0];
      return `https://www.youtube.com/embed/${videoUrls}`;
    }
  };

  // submit task fn
  const taskSubmit = async (e) => {
    e.preventDefault()
    const courseId = enrollId?.courseId
    const lessonId = enrollId?._id
    const userId = signUser?._id

    const res = await fetch(`${API_URL}/api/submit/submitTask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ courseId, lessonId, userId, taskId, instId, description: submitDesc })
    })
    const data = await res.json()
    const taskError = document.getElementById("taskError")
    data.message !== undefined ? taskError.innerText = data.message : taskError.innerText = ""
    if (res.ok) {
      setSubmitDesc("")
      Swal.fire({
        position: "center",
        icon: "success",
        title: "submitted task successfull",
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
  const openModalGetId = (tId, InId) => {
    setInstId(InId)
    setTaskId(tId)
    setTaskModal(true)
  }

  if (course.lessons && course.lessons?.length > 0) {
    return (
      <div>
        <section className="py-4">
          <div className="head-bg">
            <div className="head-overlay">
              <h1 className="text-center py-6 text-white">{course.title}</h1>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>{course.description}</p>
            </div>
          </div>
        </div>

        <div className="container-fluid py-5">
          <div className="row justify-content-center g-5">
            {/* progress bar */}
            <div className="row justify-content-center">
              <div className="col-md-7">
                {/* <CircularProgressbar
                  value={progressBar}
                  text={`${ Math.round(progressBar) } % `}
                  styles={buildStyles({
                    pathColor: "green", // Progress color
                    textColor: "black", // Text color
                    trailColor: "#d6d6d6",
                  })}
                /> */}
                <h5 className="text-center">Your Progress</h5>
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${progressBar} % ` }}
                    aria-valuenow={progressBar}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {Math.round(progressBar)}%
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="col-md-3" style={{ borderRight: "1px solid #9a9a9a" }}>
              <h5>Lessons</h5>
              <p>{course.lessons?.filter(less => less.status === "Y").length}/{course.lessons?.length}</p>
              {course.lessons?.map((enrolled, index) => (
                <div>
                  <div key={index} className="mb-3 d-flex lessons-list">
                    <input className="form-check-input me-2" type="checkbox" value="" id={`lesson - checkbox - ${index}`}
                      disabled={enrolled.status === "N"}
                      onChange={() => handleCheckChange(index)}
                      checked={lessonCheck[index] || false}
                    ></input>
                    <h6
                      className={` ${enrolled.status === "Y" ? "clickable" : "disabled"}`}
                      onClick={() => {
                        if (enrolled.status === "Y") {
                          setEnrollId(enrolled);
                        }
                      }}
                      style={{
                        cursor: enrolled.status === "Y" ? "pointer" : "not-allowed",
                        color: enrolled.status === "Y" ? "" : "gray",
                      }}
                    >
                      {enrolled.title}
                    </h6>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
            <div className="col-md-8">
              {course.lessons?.filter(enrolled => enrolled._id === enrollId._id).map((enrolled, index) => (
                <div key={index}>
                  {enrolled.videoUrls &&
                    enrolled.videoUrls.map((urls, index) => {
                      return (
                        <div key={index}>
                          <iframe
                            src={iFrameUrl(urls)}
                            title="YouTube video player"
                            frameborder="0"
                            height="400px"
                            width="800px"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                          ></iframe>
                        </div>
                      );
                    })}
                  <div className="mt-3">
                    <h6>Description</h6>
                    <p> {enrolled.description}</p>
                  </div>
                </div>
              ))}
              {/* Assignment */}
              {filterTask?.length > 0 && (
                <h2>Lesson Assignments</h2>
              )}
              {filterTask?.length > 0 && filterTask.map((task, index) => (
                <h6 className="p-3" onClick={task.status === "Y" ? () => openModalGetId(task._id, task.userId?._id) : null} style={{
                  border: "1px solid #D3D3D3",
                  cursor: task.status === "Y" ? "pointer" : "not-allowed",
                  color: task.status === "Y" ? "inherit" : "gray",
                }}>{task.title}</h6>
              ))}
            </div>
          </div>l
        </div>

        {/*Task modal */}
        <Modal
          size="lg"
          show={taskModal}
          onHide={() => setTaskModal(false)}
          aria-labelledby="example-modal-sizes-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title">
              Assignment Modal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {filterTask?.map((task, index) => (
              <div className="row" key={index}>
                <div className="col-md-6">
                  <h6>{task.title}</h6>
                  <p
                    className="m-0"
                    dangerouslySetInnerHTML={{
                      __html: task.description,
                    }}
                  ></p>
                </div>
                <div className="col-md-6">
                  {task.file?.includes("video") ? (
                    <video width="100%" controls>
                      <source src={task.file} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : task.file?.includes("pdf") ? (
                    <div>
                      <embed src={task.file} type="application/pdf" width="100%" height="300px" />
                    </div>
                  ) : task.file?.includes("image") ? (
                    <img src={task.file} alt="Task image" style={{ width: "100%" }} />
                  ) : (
                    <p>{task.file}</p>
                  )}
                </div>
              </div>
            ))}

            <form onSubmit={taskSubmit}>
              <div id="taskError" className="text-danger"></div>
              <textarea name="description" className="form-control my-3" value={submitDesc} onChange={(e) => setSubmitDesc(e.target.value)} rows={10} placeholder="Add your answer here"></textarea>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">Submit Task</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div >
    );
  } else {
    return <div>
      <section>
        <div className="head-bg">
          <div className="head-overlay">
            <h1 className="text-center py-6 text-white">{course.title}</h1>
          </div>
        </div>
      </section>
      <section className="enrolled-course-description py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="section-heading">
                <h2>Course Overview</h2>
              </div>
              <p>{course.description}</p>
              <div className="section-heading">
                <h2>Lessons</h2>
              </div>
              <h5 className="b-1">The instructor has not added any lesson to this course yet.</h5>
            </div>
          </div>
        </div>
      </section>
    </div>;
  }
}

export default EnrolledCoursePage;
