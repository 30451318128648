import React, { useEffect, useState } from 'react'
import EnrolledContext from './EnrolledContext'
import emailjs from '@emailjs/browser';
import API_URL from '../ApuUrl';

function EnrolledProvider({ children }) {
    const [request, setRequest] = useState([])
    const EnrolRequests = async () => {
        const res = await fetch(`${API_URL}/api/enrollCourse/enrlRequests`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setRequest(data)
    }
    useEffect(() => {
        EnrolRequests()
    }, [])

    const AcceptRequest = async (id) => {
        const res = await fetch(`${API_URL}/api/enrollCourse/acceptStatus/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        if (res.ok) {
            emailjs
                .send(
                    "service_rb04ohy",
                    "template_suumsz5",
                    {
                        name: data.studentId?.name,
                        email: data.studentId?.email,
                        title: data.courseId?.title
                    },
                    "SHPDxLrF-3wQqqLD9"
                )
        }

        EnrolRequests()
    }
    const RejectRequest = async (id) => {
        await fetch(`${API_URL}/api/enrollCourse/rejectStatus/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            }
        })
        EnrolRequests()
    }

    return (
        <EnrolledContext.Provider value={{ request, AcceptRequest, RejectRequest, EnrolRequests }}>
            {children}
        </EnrolledContext.Provider>
    )
}

export default EnrolledProvider
