import React, { useContext, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import CourseContext from '../../contexts/CourseContext';
import AllConfigCerti from './AllConfigCerti';
import API_URL from '../../ApuUrl';
import ConfigContext from '../../contexts/ConfigCerti';

export default function ConfigureCertificate() {
    const [lgShow, setLgShow] = useState(false);
    const { allCourse } = useContext(CourseContext)
    const { uploadedConfigCerti } = useContext(ConfigContext)
    const generateDate = () => {
        const today = new Date()
        return today.toISOString().split('T')[0];
    }

    const [certiCredentials, setCertiCredentials] = useState({
        courseId: "",
        description: "",
        configureDate: generateDate()
    })

    const CoursesToConfig = [
        { value: "", label: "Select course to configure" },
        ...allCourse?.map(course => (
            { value: course._id, label: course.title }
        ))
    ]

    const submitConfigCerti = async (e) => {
        e.preventDefault()
        const { courseId, description, configureDate } = certiCredentials
        const res = await fetch(`${API_URL}/api/configCerti/confCertificate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ courseId, description, configureDate })
        })
        const data = await res.json()
        const certiError = document.getElementById("certiError")
        data.message !== undefined ? certiError.innerText = data.message : certiError.innerText = ""
        if (res.ok) {
            setCertiCredentials({
                courseId: "",
                description: ""
            })
            uploadedConfigCerti()
        }
    }

    const onchange = (e) => {
        const { name, value } = e.target;
        setCertiCredentials({ ...certiCredentials, [name]: value })
    }
    return (
        <>
            <div className="d-flex justify-content-end my-4">
                <Button onClick={() => setLgShow(true)}>Configure Certificate</Button>
            </div>

            <AllConfigCerti />

            {/* config certificate modal */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Configure Certificate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={submitConfigCerti}>
                        <div id="certiError" className='text-danger'></div>
                        <label className="mt-3">Select Course <span className="text-danger">*</span></label>
                        <select name="courseId" value={certiCredentials.courseId} className='form-control mb-3' onChange={onchange}>
                            {CoursesToConfig?.map((option, ind) => (
                                <option key={ind} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        <label className="mt-3">Configure Date <span className="text-danger">*</span></label>
                        <input type="date" name='configureDate' value={certiCredentials.configureDate} className='form-control mb-3' onChange={onchange} />
                        <label className="mt-3">Description <span className="text-danger">*</span></label>
                        <textarea name="description" value={certiCredentials.description} rows={10} className='form-control mb-3' placeholder='Description goes here' onChange={onchange}></textarea>
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-primary' type='submit'>Submit</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}
