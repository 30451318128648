import React from 'react'
import AllResults from '../../adminside/Result/AllResults'

export default function StudentResult() {
    return (
        <>
            <AllResults />
        </>
    )
}
