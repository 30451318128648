import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

function LessonAccord() {

  const videos = [
    {
      id: 1,
      url: '<iframe width="560" height="315" src="https://www.youtube.com/embed/RGKi6LSPDLU?si=MNp5KSMW0uARNFmn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>"'
    },
    {
      id: 2,
      url: "https://www.youtube.com/watch?v=SqcY0GlETPk",
    },
    {
      id: 3,
      url: "https://www.youtube.com/watch?v=SqcY0GlETPk",
    },
    {
      id: 4,
      url: "https://www.youtube.com/watch?v=SqcY0GlETPk",
    },
  ]

  const [lessonVideos, setLessonVideo] = useState(videos)

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Accordion Item #1</Accordion.Header>
        <Accordion.Body>
          <section>
            <div className="container">
              <div className="row">
                
                <div className="col-md-3">
                </div>
                
              </div>
            </div>
          </section>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default LessonAccord;