import React from 'react'
import SubmittedTasks from '../../adminside/submitTasks/SubmittedTasks'

export default function InstructorSubmitTasks() {
    return (
        <>
            <SubmittedTasks />
        </>
    )
}
