import React, { useContext, useState } from "react"
import Swal from "sweetalert2"
import MyContext from "../../contexts/Mycontexts"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import SchoolContext from "../../contexts/SchoolContext";
import SchoolModals from "./SchoolModals";
import API_URL from "../../ApuUrl";

export default function Addschool() {
    const { signUser } = useContext(MyContext)
    const { allSchool, allSchools, schoolId, deleteSchool } = useContext(SchoolContext)

    const [pageNumber, setPageNumber] = useState(1)
    const [searchOption, setSearchOption] = useState("")
    const [School, setSchool] = useState({
        name: "", email: "", number: "", city: "", address: "", detail: "", fpNumber: "", category: "", image: "", userId: ""
    })
    const emailError = document.getElementById("emailError");
    const fieldsMissingError = (fields) => {
        let emptyFieldError = false;

        if (!fields.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            emailError.innerText = "Please enter a valid email address";
            emptyFieldError = true;
        } else {
            emailError.innerText = "";
        }

        return emptyFieldError;
    }
    // add School
    async function addSchool(e) {
        e.preventDefault();
        const { name, email, number, city, address, detail, fpNumber, category, image } = School;
        const hasError = fieldsMissingError(School)
        if (hasError) {
            return;
        }
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("category", category);
        formData.append("address", address);
        formData.append("image", image);
        formData.append("number", number);
        formData.append("fpNumber", fpNumber);
        formData.append("detail", detail);
        formData.append("city", city);
        formData.append("userId", signUser._id);

        const res = await fetch(`${API_URL}/api/school/addSchool`, {
            method: "POST",
            body: formData
        });
        const data = await res.json();

        const schoolFormError = document.getElementById("schoolFormError")
        data.message !== undefined ? schoolFormError.innerText = data.message : schoolFormError.innerText = ""
        if (res.ok) {
            setSchool({
                name: "", email: "", number: "", city: "", address: "", detail: "", fpNumber: "", category: "", image: ""
            });
            Swal.fire({
                position: "center",
                icon: "success",
                title: "School has been created",
                showConfirmButton: false,
                timer: 1500
            });
            allSchools();
        }
    }

    const onchange = (e) => {
        if (e.target.files) {
            setSchool({ ...School, image: e.target.files[0] })
        } else {
            setSchool({ ...School, [e.target.name]: e.target.value })
        }
    };


    // pagination implement 
    const schoolPerPage = 15
    const lastSchoolIndex = pageNumber * schoolPerPage
    const firstSchoolIndex = lastSchoolIndex - schoolPerPage
    const schoolsData = allSchool && allSchool.filter(school => school.status === "Approved").filter(school => school.name.toLowerCase().includes(searchOption.toLowerCase()) || school.city.toLowerCase().includes(searchOption.toLowerCase())).slice(firstSchoolIndex, lastSchoolIndex)

    return (
        <div className="container mt-3">
            <div className="row d-flex justify-content-center">
                <div className="col-md-4 col-8">
                    <input type="text" className="form-control" value={searchOption} onChange={(e) => setSearchOption(e.target.value)} placeholder="Search By name/city" />
                </div>
                <div className="d-flex justify-content-end col-md-7 col-4">
                    <button className="btn btn-primary mb-5" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="fas fa-plus"></i> Add School</button>
                </div>
                <div className="col-md-11">
                    <div className="table-container" style={{ overflowX: "auto" }}>
                        <table className="table table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Sr#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">City</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {schoolsData && schoolsData.map((school, ind) => {
                                    return <tr key={ind}>
                                        <td>{firstSchoolIndex + ind + 1}</td>
                                        <td>{school.name}</td>
                                        <td>{school.city}</td>
                                        <td>{school.address}</td>
                                        <td>{school.status}</td>
                                        <td><i className="fa fa-eye me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" onClick={() => schoolId(school._id)}></i>
                                            <i className="fa fa-pen me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" onClick={() => schoolId(school._id)}></i>
                                            <i className="fa fa-trash" onClick={() => deleteSchool(school._id)}></i>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-end my-3">
                        {[...Array(Math.ceil(allSchool.length / schoolPerPage)).keys()].map((number) => {
                            return <button key={number + 1} className="btn btn-primary mx-1" onClick={() => setPageNumber(number + 1)}>{number + 1}</button>
                        })}
                    </div>
                </div>
            </div>

            {/* {/ course modal /} */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Add School</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div id="schoolFormError" className="text-danger"></div>
                            <form onSubmit={addSchool}>
                                <div className="row mb-2">
                                    <div className="col-md-6">
                                        <label className="mt-3">Name <span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" placeholder="Name" name="name" value={School.name} onChange={onchange} />
                                        <div id="nameError" className="text-danger"></div>
                                        <label className="mt-3">School Number <span className="text-danger">*</span></label>
                                        <PhoneInput
                                            className="phonInput"
                                            inputStyle={{
                                                border: "none",
                                                boxShadow: "none"
                                            }}
                                            country={'pk'}
                                            name="number"
                                            value={School.number}
                                            onChange={(value, country, event, formattedValue) => {
                                                onchange({
                                                    target: {
                                                        name: 'number',
                                                        value: formattedValue
                                                    }
                                                });
                                            }}
                                        />
                                        <div id="numberError" className="text-danger"></div>

                                        <label className="mt-3">Address <span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" placeholder="Address" name="address" value={School.address} onChange={onchange} />
                                        <div id="addressError" className="text-danger"></div>
                                        <label className="mt-3">Focal Person Number <span className="text-danger">*</span></label>
                                        <PhoneInput
                                            className="phonInput"
                                            inputStyle={{
                                                border: "none",
                                                boxShadow: "none"
                                            }}
                                            country={'pk'}
                                            name="fpNumber"
                                            value={School.fpNumber}
                                            onChange={(value, country, event, formattedValue) => {
                                                onchange({
                                                    target: {
                                                        name: 'fpNumber',
                                                        value: formattedValue
                                                    }
                                                });
                                            }}
                                        />
                                        <div id="focalError" className="text-danger"></div>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="mt-3">Email <span className="text-danger">*</span></label>
                                        <input className="form-control" type="email" placeholder="Email" name="email" value={School.email} onChange={onchange} />
                                        <div id="emailError" className="text-danger"></div>
                                        <label className="mt-3">City <span className="text-danger">*</span></label>
                                        <input className="form-control" type="text" placeholder="City" name="city" value={School.city} onChange={onchange} />
                                        <div id="cityError" className="text-danger"></div>
                                        <label className="mt-3">Category <span className="text-danger">*</span></label>
                                        <select name="category" className="form-control" value={School.category} onChange={onchange}>
                                            <option value="">Select Category</option>
                                            <option value="private">Private</option>
                                            <option value="public">Public</option>
                                        </select>
                                        <div id="categoryError" className="text-danger"></div>
                                        <label className="mt-3">School Image <span className="text-danger">*</span></label>
                                        <input className="form-control" type="file" placeholder="Image" name="image" onChange={onchange} />
                                    </div>

                                    <div className="col-md-12">
                                        <label className="mt-3">School Detail <span className="text-danger">*</span></label>
                                        <textarea className="form-control" name="detail" id="" cols="30" rows="10" placeholder="School Detail" value={School.detail} onChange={onchange}></textarea>
                                        <div id="detailError" className="text-danger"></div>
                                        <div className="d-flex justify-content-center">
                                            <button type="submit" className="btn btn-primary mt-3 px-4 py-2">Add School</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <SchoolModals />
        </div>
    )
}