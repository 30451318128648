import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../contexts/Mycontexts";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import Offcanvas from "react-bootstrap/Offcanvas";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CourseContext from "../../contexts/CourseContext";
import EnrolledContext from "../../contexts/EnrolledContext";
import API_URL from "../../ApuUrl";

export default function UserProfileCourses() {
  const { allCourse, CourseById, getCourse, getCourseCat } = useContext(CourseContext);
  const { EnrolRequests } = useContext(EnrolledContext)
  const { signUser } = useContext(MyContext);
  const [category, setCategory] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [searchName, setSearchName] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  const Enrollment = async (id) => {
    const courseId = id
    const studentId = signUser?._id
    const res = await fetch(`${API_URL}/api/enrollCourse/enrollments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ courseId, studentId }),
    });

    if (res.ok) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Enrollment request send successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      EnrolRequests()
    }
  };

  const getCategory = async () => {
    const res = await fetch(
      `${API_URL}/api/category/getcategory`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    setCategory(data);
  };
  useEffect(() => {
    getCategory();
  }, []);

  const showDetail = (simpleid) => {
    CourseById(simpleid)
    handleShow()
  }
  return (
    <div>
      <section className="courses-section py-4 bg-white">
        <div className="container">
          <div className="row g-4">
            {allCourse &&
              allCourse
                .filter((course) =>
                  course.title.toLowerCase().includes(searchName.toLowerCase())
                )
                .filter((course) => course.categoryId.includes(searchCategory))
                .reverse()
                .map((data) => {
                  return (
                    <div className="col-md-6">
                      <div className="card courses">
                        <div className="course-title p-4">
                          <p className="bold-p">
                            {data.title.length > 20
                              ? data.title.slice(0, 20) + "..."
                              : data.title}
                          </p>

                          <p>
                            Instructor Name: <b>{data.instructorName}</b>
                          </p>
                          <p>
                            Duration: <b>{data.duration}</b>
                          </p>
                        </div>
                        <div className="join mb-2">
                          <Button className="first-button" onClick={() => showDetail(data._id)} >
                            View Details
                          </Button>

                          <button className="second-button float-end"
                            onClick={() => Enrollment(data._id)}
                          >
                            Enroll Now
                          </button>

                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </section>

      {/* {/ Course Details Modal /} */}

      <Offcanvas style={{ width: '80%' }} show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>

        </Offcanvas.Header>
        <Offcanvas.Body>
          <h1>{getCourse.title}</h1>
          <div className="overview bg-white p-3 mb-4">
            <h2>Course Overview</h2>
            <p style={{ fontWeight: "initial" }}>{getCourse.description}</p>

            <ul style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}>
              <li>
                <CheckCircleIcon
                  className="list-icon"
                  style={{ color: "var(--secondary-color)" }}
                />
                &nbsp;Course Instructor:{" "}
                <strong> {getCourse.instructorName} </strong>
              </li>
              <li>
                <CheckCircleIcon
                  className="list-icon"
                  style={{ color: "var(--secondary-color)" }}
                />
                &nbsp;Course Duration: <strong> {getCourse.duration} </strong>
              </li>
              <li>
                <CheckCircleIcon
                  className="list-icon"
                  style={{ color: "var(--secondary-color)" }}
                />
                &nbsp;Course Category:{" "}
                <strong> {getCourseCat.category} </strong>
              </li>
              {(getCourse.days && getCourse.days !== "undefined") && (

                <li>
                  <CheckCircleIcon
                    className="list-icon"
                    style={{ color: "var(--secondary-color)" }}
                  />
                  &nbsp;Classes In A Week:{" "}
                  <strong> {getCourse.days} </strong>
                </li>
              )}
              {(getCourse.timeSlot && getCourse.timeSlot !== "undefined") && (

                <li>
                  <CheckCircleIcon
                    className="list-icon"
                    style={{ color: "var(--secondary-color)" }}
                  />
                  &nbsp;Class Timings:{" "}
                  <strong> {getCourse.timeSlot} </strong>
                </li>
              )}
            </ul>

            <div className="course-img">
              <img src={getCourse.image} alt="" className="img-fluid" />
            </div>
            <div className="row">
              {getCourse.learning && (
                <div className="col-md-5 listing mt-4">
                  <div className="learning-outcomes py-2">
                    <h3>{getCourse.moduleName1}</h3>
                    <ul
                      style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                    >
                      {getCourse.learning &&
                        getCourse.learning.split("\n").map((line, lineindex) => {
                          return (
                            <li key={lineindex}>
                              <CheckCircleIcon className=" user-course-detail-icon" />
                              &nbsp;{line}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}

              {getCourse.content && (
                <div className="col-md-5 listing mt-4">
                  <div className="learning-outcomes py-2">
                    <h3>{getCourse.moduleName2}</h3>
                    <ul
                      style={{ lineHeight: "2.5rem", padding: 0, margin: 0 }}
                    >
                      {getCourse.content &&
                        getCourse.content.split("\n").map((line, lineindex) => {
                          return (
                            <li key={lineindex}>
                              <CheckCircleIcon className="user-course-detail-icon" />
                              &nbsp;{line}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}


            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

    </div>
  );
}
