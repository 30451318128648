import React, { useContext, useState } from 'react'
import Swal from 'sweetalert2';
import CourseContext from '../../contexts/CourseContext';
import CategoryContext from '../../contexts/CategoryContext';
import { Modal } from 'react-bootstrap';
import AddLessonModal from '../../adminside/Lesson/AddLessonModal';
import API_URL from '../../ApuUrl';

function InstructorCourse() {
  const { deleteCourse, CourseById, checCourse, checkCourse, courseCategory, allCourses, setLessonCourseId, getCourseCat, getCourse, setGetCourse } = useContext(CourseContext);
  const { category } = useContext(CategoryContext);
  const [lgShow, setLgShow] = useState(false);
  const [editShow, setEditShow] = useState(false);

  const updateCourse = async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    });

    if (isConfirmed) {
      const { title, duration, level, description, categoryId, learning, content, image, instructorName, timeSlot, days, moduleName1, moduleName2,
      } = getCourse;
      const formData = new FormData();
      formData.append("title", title);
      formData.append("instructorName", instructorName);
      formData.append("timeSlot", timeSlot);
      formData.append("days", days);
      formData.append("moduleName1", moduleName1);
      formData.append("moduleName2", moduleName2);
      formData.append("duration", duration);
      formData.append("level", level);
      formData.append("description", description);
      formData.append("categoryId", categoryId);
      formData.append("learning", learning);
      formData.append("content", content);
      formData.append("image", image);
      await fetch(
        `${API_URL}/api/course/updatecourse/${getCourse._id}`,
        {
          method: "PUT",
          body: formData,
        }
      );
      Swal.fire("Saved!", "", "success");
    } else {
      Swal.fire("Changes are not saved", "", "info");
    }
    allCourses();
    checkCourse()
  };

  const onchnge = (e) => {
    if (e.target.files) {
      setGetCourse({ ...getCourse, image: e.target.files[0] });
    } else {
      setGetCourse({ ...getCourse, [e.target.name]: e.target.value });
    }
  };

  const openModalViewCourse = (id) => {
    setLgShow(true)
    CourseById(id)
  }
  const openModalEditCourse = (id) => {
    setEditShow(true)
    CourseById(id)
  }

  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-md-11">
          <div className="table-container" style={{ overflowX: "auto" }}>
            <table className="table table-responsive">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th scope="col">Category</th>
                  <th scope="col">Lesson</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {checCourse &&
                  checCourse.map((course, index) => {
                    return (
                      <tr>
                        <td>{course.title}</td>
                        {courseCategory[index] && (
                          <td>{courseCategory[index].category}</td>
                        )}
                        <td>
                          <AddLessonModal courseIdd={course._id} />
                        </td>
                        <td>
                          <i
                            className="fa fa-eye me-2"
                            onClick={() => openModalViewCourse(course._id)}
                          ></i>
                          <i
                            className="fa fa-pen me-2"
                            onClick={() => openModalEditCourse(course._id)}
                          ></i>
                          <i
                            className="fa fa-trash"
                            onClick={() => deleteCourse(course._id)}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

        </div>
      </div>
      {/* {/ view modal /} */}
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            View Course Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="title" className="mt-3">
                  Title
                </label>
                <input
                  className="form-control"
                  value={getCourse.title}
                  id="title"
                  type="text"
                  placeholder="Title"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="Duration" className="mt-3">
                  Duration
                </label>
                <input
                  className="form-control"
                  value={getCourse.duration}
                  id="Duration"
                  type="text"
                  placeholder="Duration"
                />
                <label htmlFor="level" className="mt-3">
                  Course Level
                </label>
                <input
                  className="form-control"
                  value={getCourse.level}
                  type="text"
                  id="level"
                  placeholder="Level"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="category" className="mt-3">
                  Category
                </label>
                {getCourseCat && (
                  <input
                    className="form-control"
                    value={getCourseCat.category}
                    id="title"
                    type="text"
                    placeholder="Category"
                  />
                )}
                <label htmlFor="category" className="mt-3">
                  Image
                </label>
                <input
                  className="form-control"
                  value={getCourse.image}
                  id="title"
                  type="text"
                  placeholder="Image"
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="role" className="mt-3">
                  Learning Outcomes
                </label>
                <textarea
                  name="course description"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={getCourse.learning}
                  type="text"
                  placeholder="role"
                ></textarea>
              </div>
              <div className="col-md-6">
                <label htmlFor="role" className="mt-3">
                  Course Contents
                </label>
                <textarea
                  name="course description"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={getCourse.content}
                  type="text"
                  placeholder="role"
                ></textarea>
              </div>
              <div className="col-md-12">
                <label htmlFor="role" className="mt-3">
                  Course Description
                </label>
                <textarea
                  name="course description"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={getCourse.description}
                  type="text"
                  placeholder="role"
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* {/ Edit modal /} */}
      <Modal
        size="lg"
        show={editShow}
        onHide={() => setEditShow(false)}
        aria-labelledby="example-modal-sizes-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title">
            Edit Course Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="title" className="mt-3">
                  Title
                </label>
                <input
                  className="form-control"
                  name="title"
                  value={getCourse.title}
                  id="title"
                  type="text"
                  placeholder="Title"
                  onChange={onchnge}
                />
                <label htmlFor="Duration" className="mt-3">
                  Duration
                </label>
                <input
                  className="form-control"
                  name="duration"
                  value={getCourse.duration}
                  id="Duration"
                  type="text"
                  placeholder="Duration"
                  onChange={onchnge}
                />
                <label htmlFor="level" className="mt-3">
                  Course Level
                </label>
                <select
                  className="form-control"
                  name="level"
                  value={getCourse.level}
                  type="text"
                  id="level"
                  placeholder="Level"
                  onChange={onchnge}
                >
                  <option value="">Select Course Level</option>
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="advanced">Advanced</option>
                </select>
                <label htmlFor="days" className="mt-3">
                  Class Schedule In A Week
                </label>
                <input
                  className="form-control"
                  name="days"
                  value={getCourse.days}
                  id="days"
                  type="text"
                  onChange={onchnge}
                />
                <label htmlFor="moduleName1" className="mt-3">
                  Module Name 1
                </label>
                <input
                  className="form-control"
                  name="moduleName1"
                  value={getCourse.moduleName1}
                  id="moduleName1"
                  type="text"
                  placeholder="Module Name 1"
                  onChange={onchnge}
                />
                <label htmlFor="role" className="mt-3">
                  Learning Outcomes
                </label>
                <textarea
                  name="learning"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={getCourse.learning}
                  type="text"
                  placeholder="role"
                  onChange={onchnge}
                ></textarea>
              </div>

              <div className="col-md-6">
                <label htmlFor="instructorName" className="mt-3">
                  Instructor Name
                </label>
                <input
                  className="form-control"
                  name="instructorName"
                  value={getCourse.instructorName}
                  id="instructorName"
                  type="text"
                  placeholder="Instructor Name"
                  onChange={onchnge}
                />
                <label htmlFor="category" className="mt-3">
                  Category
                </label>
                <select
                  className="form-control"
                  id="course level"
                  name="categoryId"
                  value={getCourse.categoryId}
                  onChange={onchnge}
                >
                  {category &&
                    category.map((data) => {
                      return (
                        <option value={data._id}>{data.category}</option>
                      );
                    })}
                </select>
                <label htmlFor="image" className="mt-3">
                  Featured Image
                </label>
                <input
                  className="form-control"
                  type="file"
                  placeholder="Image"
                  name="image"
                  onChange={onchnge}
                />
                <label htmlFor="timeSlot" className="mt-3">
                  Time Slots
                </label>
                <input
                  className="form-control"
                  name="timeSlot"
                  value={getCourse.timeSlot}
                  id="timeSlot"
                  type="text"
                  onChange={onchnge}
                />
                <label htmlFor="moduleName2" className="mt-3">
                  Module Name 2
                </label>
                <input
                  className="form-control"
                  name="moduleName2"
                  value={getCourse.moduleName2}
                  id="moduleName2"
                  type="text"
                  placeholder="Module Name 2"
                  onChange={onchnge}
                />
                <label htmlFor="role" className="mt-3">
                  Course Content
                </label>
                <textarea
                  name="content"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={getCourse.content}
                  type="text"
                  placeholder="role"
                  onChange={onchnge}
                ></textarea>
              </div>
              <div className="col-md-12">
                <label htmlFor="role" className="mt-3">
                  Course Description
                </label>
                <textarea
                  name="description"
                  id="desc"
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={getCourse.description}
                  type="text"
                  placeholder="role"
                  onChange={onchnge}
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary mt-3 text-center"
                onClick={updateCourse}
              >
                Update
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InstructorCourse
