import React, { useContext, useEffect, useState } from 'react'
import CourseContext from './CourseContext'
import Swal from "sweetalert2";
import MyContext from './Mycontexts';
import API_URL from '../ApuUrl';

export default function CourseProvider({ children }) {
    const { signUser } = useContext(MyContext)
    const [allCourse, setAllCourse] = useState([])
    const [courseCategory, setCourseCategory] = useState([])
    const [getCourse, setGetCourse] = useState([])
    const [category, setCategory] = useState([])
    const [checCourse, setChecCourse] = useState()
    const [getCourseData, setGetCourseData] = useState([])
    const [checCategory, setChecCategory] = useState()
    const [getCourseCat, setGetCourseCat] = useState([])
    const [getCourseDataCat, setGetCourseDataCat] = useState([])

    const allCourses = async () => {
        await fetch(`${API_URL}/api/course/getAllCourses`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => {
                setAllCourse(data.allCourses)
                setCourseCategory(data.onlyCategory)
            })
    }

    const CourseById = async (id) => {
        await fetch(`${API_URL}/api/course/getcourse/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json())
            .then(data => {
                setGetCourseCat(data.categoryy)
                setGetCourse(data.courseId)
            })
    }
    const checkCourse = async () => {
        if (!signUser || !signUser._id) {
            return;
        }
        const res = await fetch(`${API_URL}/api/course/checkCourse/${signUser._id}`, {
            method: "GET",
        })
        const data = await res.json()
        setChecCategory(data.categoryy)
        setChecCourse(data.courseId)
    }


    // Delete Course
    const deleteCourse = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your course has been deleted successfully.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(
                `${API_URL}/api/course/deletecourse/${id}`,
                {
                    method: "delete",
                }
            );
            allCourses();
            checkCourse()
        }
    };

    const getOnlyCategory = async () => {
        const res = await fetch(`${API_URL}/api/course/getOnlyCategory`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setCategory(data)
    }

    useEffect(() => {
        if (signUser && signUser._id) {
            checkCourse();
        }
        allCourses()
        checkCourse()
        getOnlyCategory()
    }, [signUser])

    return (
        <CourseContext.Provider value={{ allCourse, courseCategory, getCourse, getCourseData, getCourseCat, getCourseDataCat, allCourses, CourseById, deleteCourse, checCategory, category, checCourse, setGetCourse, checkCourse }}>
            {children}
        </CourseContext.Provider>
    )
}
