import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Form, Modal } from 'react-bootstrap';
import TaskContext from '../../contexts/TaskController';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MyContext from '../../contexts/Mycontexts';
import API_URL from '../../ApuUrl';

export default function AssignedTasks() {
    const { tasks, getTaskById, taskById, setTaskById, assignedTasks, delTaskById } = useContext(TaskContext)
    const { signUser } = useContext(MyContext)
    const filterTasks = signUser?.role === "admin" ? tasks : tasks?.filter(task => task.userId?._id === signUser?._id)
    const [taskViewShow, setTaskViewShow] = useState(false)
    const [editShow, setEditShow] = useState(false);

    const formateDate = (date) => {
        return new Date(date).toLocaleDateString()
    }

    const openModalViewTask = (id) => {
        getTaskById(id)
        setTaskViewShow(true)
    }

    const openModalEditTask = (id) => {
        getTaskById(id)
        setEditShow(true)
    }

    const updateTask = async (e) => {
        e.preventDefault()
        const { title, dueDate, description, file } = taskById
        const formData = new FormData()
        formData.append("title", title)
        formData.append("dueDate", dueDate)
        formData.append("description", description)
        formData.append("file", file)

        const res = await fetch(`${API_URL}/api/task/assignedTasks/${taskById._id}`, {
            method: "PUT",
            body: formData
        })
        const data = await res.json()
        if (res.ok) {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "task updated successfully",
                showConfirmButton: false,
                timer: 1500
            });
            assignedTasks()
        }

    }

    const handleDescChange = (html) => {
        setTaskById({ ...taskById, description: html })
    }
    const onchange = (e) => {
        const { files, name, value } = e.target
        if (files) {
            setTaskById({ ...taskById, file: files[0] })
        } else {
            setTaskById({ ...taskById, [name]: value })
        }
    }

    const updateStatus = async (id) => {
        const res = await fetch(`${API_URL}/api/task/updateStatus/${id}`, {
            method: "PUT"
        })
        if (res.ok) {
            assignedTasks()
        }
    }

    const formateDueDate = (date) => {
        if (date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
    return (
        <>
            <div className="Container py-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-container" style={{ overflowX: "auto" }}>
                            <table className="table table-responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr#</th>
                                        <th scope="col">Course Title</th>
                                        <th scope="col">Lesson Title</th>
                                        <th scope="col">Task Title</th>
                                        <th scope="col">dueDate</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterTasks?.map((task, index) => {
                                        return <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{task.courseId?.title}</td>
                                            <td>{task.lessonId?.title}</td>
                                            <td>{task.title}</td>
                                            <td>{formateDate(task.dueDate)}</td>
                                            <td>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={task.status === "Y"}
                                                    onClick={() => updateStatus(task._id)}
                                                />
                                            </td>
                                            <td><i className="fa fa-eye me-2" onClick={() => openModalViewTask(task._id)} ></i>
                                                <i className="fa fa-pen me-2" onClick={() => openModalEditTask(task._id)}></i>
                                                <i className="fa fa-trash" onClick={() => delTaskById(task._id)}></i>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* {/ view modal /} */}
            <Modal
                size="lg"
                show={taskViewShow}
                onHide={() => setTaskViewShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        View Task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div id="error" className="text-danger text-center"></div>
                        <label htmlFor="name" className="mt-3">Lesson Title</label>
                        <input className="form-control" value={taskById.title} id="name" type="name" placeholder="Name" />
                        <label htmlFor="email" className="mt-3">Assignment/Task</label>
                        <ReactQuill
                            className="mb-3"
                            theme="snow"
                            value={taskById.description}
                        />
                        <label htmlFor="name" className="mt-3">Due Date</label>
                        <input className="form-control mb-3" value={formateDate(taskById.dueDate)} id="name" type="name" placeholder="Name" />
                        {taskById.file?.length > 0 && (
                            taskById.file?.includes("video") ? (
                                <video width="100%" controls>
                                    <source src={taskById.file} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : taskById.file?.includes("pdf") ? (
                                <div>
                                    <embed src={taskById.file} type="application/pdf" width="100%" height="300px" />
                                </div>
                            ) : taskById.file?.includes("image") ? (
                                <img src={taskById.file} alt="task By Id image" style={{ width: "100%" }} />
                            ) : (
                                <p>{taskById.file}</p>
                            )

                        )}
                    </form>
                </Modal.Body>
            </Modal>

            {/* {/ Edit modal /} */}
            <Modal
                size="lg"
                show={editShow}
                onHide={() => setEditShow(false)}
                aria-labelledby="example-modal-sizes-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title">
                        Edit Task
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div id="error" className="text-danger text-center"></div>
                        <label htmlFor="name" className="mt-3">Lesson Title</label>
                        <input className="form-control" value={taskById.title} name='title' onChange={onchange} />
                        <label htmlFor="name" className="mt-3">Lesson Title</label>
                        <div className='d-flex justify-content-between'>
                            <input className="form-control" name='file' type='file' onChange={onchange} style={{ width: "70%" }} />
                            {taskById.file?.length > 0 && (
                                <div style={{ width: "20%" }}>
                                    {taskById.file?.includes("video") ? (
                                        <video width="100%" controls>
                                            <source src={taskById.file} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : taskById.file?.includes("pdf") ? (
                                        <div>
                                            <embed src={taskById.file} type="application/pdf" width="100%" />
                                        </div>
                                    ) : taskById.file?.includes("image") ? (
                                        <img src={taskById.file} alt="task By Id image" style={{ width: "100%" }} />
                                    ) : (
                                        <p>{taskById.file}</p>
                                    )}
                                </div>
                            )}
                        </div>
                        <label htmlFor="email" className="mt-3">Description</label>
                        <ReactQuill
                            className="mb-3"
                            theme="snow"
                            name="description"
                            value={taskById.description}
                            onChange={handleDescChange}
                        />

                        <label htmlFor="name" className="mt-3">Due Date</label>
                        <input className="form-control" name='dueDate' type="date" value={formateDueDate(taskById.dueDate)} id="name" placeholder="Name" onChange={onchange} />
                        <div className="d-flex justify-content-center">
                            <button className='btn btn-primary' onClick={updateTask}>Update Lesson</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}
