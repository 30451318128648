import React, { useContext } from 'react'
import SchoolContext from '../../contexts/SchoolContext'
import Swal from 'sweetalert2'
import API_URL from '../../ApuUrl'

export default function SchoolModals() {
    const { getSchool, allSchools, setGetSchool } = useContext(SchoolContext)

    const updateSchool = async (e) => {
        e.preventDefault()
        const { isConfirmed } = await Swal.fire({
            title: "Do you want to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`,
        });

        if (isConfirmed) {
            const { name, number, city, address, detail, fpNumber, category, image } = getSchool

            const formData = new FormData();
            formData.append("name", name);
            formData.append("category", category);
            formData.append("address", address);
            formData.append("image", image);
            formData.append("number", number);
            formData.append("fpNumber", fpNumber);
            formData.append("detail", detail);
            formData.append("city", city);

            await fetch(`${API_URL}/api/auth/updateSchool/${getSchool._id}`, {
                method: "PUT",
                body: formData
            })
            Swal.fire("Saved!", "", "success");
        } else {
            Swal.fire("Changes are not saved", "", "info");
        }
        allSchools()
    }

    const onchnge = (e) => {
        if (e.target.name === 'number' || e.target.name === "fpNumber") {
            if (!/^\d*$/.test(e.target.value)) {
                setGetSchool((School) => ({ ...School, [e.target.name]: e.target.value.replace(/\D/g, '') }));
                return;
            }
        }
        if (e.target.files) {
            setGetSchool({ ...getSchool, image: e.target.files[0] })
        } else {
            setGetSchool({ ...getSchool, [e.target.name]: e.target.value })
        }
    }
    return (
        <>
            {/* {/ view modal /} */}
            <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">View School</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="name" className="mt-3">Name</label>
                                        <input className="form-control" type="text" placeholder="Name" name="name" value={getSchool.name} />
                                        <label htmlFor="number" className="mt-3">Number</label>
                                        <input className="form-control" type="tel" placeholder="Number" name="number" value={getSchool.number} />
                                        <label htmlFor="address" className="mt-3">Address</label>
                                        <input className="form-control" type="text" placeholder="Address" name="address" value={getSchool.address} />
                                        <label htmlFor="category" className="mt-3">Category</label>
                                        <input className="form-control" type="text" placeholder="Number" name="category" value={getSchool.category} />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="email" className="mt-3">Email</label>
                                        <input className="form-control" type="email" placeholder="Email" name="email" value={getSchool.email} />
                                        <label htmlFor="city" className="mt-3">City</label>
                                        <input className="form-control" type="text" placeholder="City" name="city" value={getSchool.city} />
                                        <label htmlFor="fpNumber" className="mt-3">Focal Person Number</label>
                                        <input className="form-control" type="tel" placeholder="Number" name="fpNumber" value={getSchool.fpNumber} />
                                        <label htmlFor="fpNumber" className="mt-3">Image</label>
                                        <input className="form-control" type="text" placeholder="image" value={getSchool.image} />
                                    </div>

                                    <div className="col-md-12">
                                        <label htmlFor="detail" className="mt-3">Detail</label>
                                        <textarea className="form-control" name="detail" id="detail" cols="30" rows="10" value={getSchool.detail}></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* {/ Edit modal /} */}
            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Edit School</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="name" className="mt-3">Name</label>
                                        <input className="form-control" type="text" placeholder="Name" name="name" value={getSchool.name} onChange={onchnge} />
                                        <label htmlFor="number" className="mt-3">Number</label>
                                        <input className="form-control" type="tel" placeholder="Number" name="number" value={getSchool.number} onChange={onchnge} />
                                        <label htmlFor="address" className="mt-3">Address</label>
                                        <input className="form-control" type="text" placeholder="Address" name="address" value={getSchool.address} onChange={onchnge} />
                                        <label htmlFor="fpNumber" className="mt-3">Focal Person Number</label>
                                        <input className="form-control" type="tel" placeholder="Focal Person Number" name="fpNumber" value={getSchool.fpNumber} onChange={onchnge} />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="email" className="mt-3">Email</label>
                                        <input className="form-control" type="email" placeholder="Email" name="email" value={getSchool.email} />
                                        <label htmlFor="city" className="mt-3">City</label>
                                        <input className="form-control" type="text" placeholder="City" name="city" value={getSchool.city} onChange={onchnge} />
                                        <label htmlFor="category" className="mt-3">Category</label>
                                        <select name="category" className="form-control" value={getSchool.category} onChange={onchnge}>
                                            <option value="">Select Category</option>
                                            <option value="private">Private</option>
                                            <option value="public">Public</option>
                                        </select>
                                        <label htmlFor="image" className="mt-3">School Image</label>
                                        <input className="form-control" type="file" placeholder="Image" name="image" onChange={onchnge} />
                                    </div>

                                    <div className="col-md-12">
                                        <label htmlFor="website" className="mt-3">Course Detail</label>
                                        <textarea className="form-control" name="detail" id="website" cols="30" rows="10" value={getSchool.detail} onChange={onchnge}></textarea>
                                        <div className="d-flex justify-content-center mt-3">
                                            <button className="btn btn-primary" onClick={updateSchool}>Update</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
