import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import SubmitContext from './SubmitContext'
import API_URL from '../ApuUrl'

function SubmitProvider({ children }) {
    const [lessonCourseId, setLessonCourseId] = useState("")
    const [submitTasks, setSubmitTasks] = useState([])
    const [submitTaskById, setSubmitTaskById] = useState([])

    const submittedTasks = async () => {
        const res = await fetch(`${API_URL}/api/submit/getSubmitTasks`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setSubmitTasks(data)
    }
    const getSubmitTaskById = async (id) => {
        const res = await fetch(`${API_URL}/api/submit/getSubmitTask/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
        const data = await res.json()
        setSubmitTaskById(data)
    }
    const delSubmitTask = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Deleted!",
                    text: "Your course has been deleted successfully.",
                    icon: "success",
                });
            }
            return result;
        });

        if (isConfirmed) {
            await fetch(
                `${API_URL}/api/submit/delSubmitTask/${id}`,
                {
                    method: "DELETE",
                }
            );
            submittedTasks();
        }
    }

    useEffect(() => {
        submittedTasks();
    }, [])


    return (
        <SubmitContext.Provider value={{ lessonCourseId, setLessonCourseId, submitTasks, getSubmitTaskById, submitTaskById, submittedTasks, delSubmitTask, setSubmitTaskById }}>
            {children}
        </SubmitContext.Provider>
    )
}

export default SubmitProvider
