import React from 'react'
import AssignedTasks from '../../adminside/Tasks/AssignedTasks'

export default function InstructorAssignedTasks() {
    return (
        <>
            <AssignedTasks />
        </>
    )
}
