import React, { useContext } from "react";
import frontendDeveloper from "../img/front-end-developer-course.avif";
import { Link } from "react-router-dom";
import CourseContext from "../contexts/CourseContext";
import MyContext from "../contexts/Mycontexts";
import Swal from "sweetalert2"
import EnrolledContext from "../contexts/EnrolledContext";
import API_URL from "../ApuUrl";

function Corusecomponent() {
  const { allCourse } = useContext(CourseContext)
  const { signUser } = useContext(MyContext)
  const { EnrolRequests } = useContext(EnrolledContext)

  const Enrollment = async (id) => {
    const courseId = id
    const studentId = signUser?._id
    const res = await fetch(`${API_URL}/api/enrollCourse/enrollments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ courseId, studentId }),
    });

    if (res.ok) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Enrollment request send successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      EnrolRequests()
    }
  };

  return (
    <div>
      <section className="courses-section py-4 bg-white">
        <div className="container">
          <div className="section-header text-center mb-4">
            <h2>Select Courses</h2>
            <h3>Explore Our Popular Courses</h3>
          </div>
          <div className="row g-4">
            {allCourse && allCourse.slice(-6).reverse().map((course) => {
              return <div className="col-md-4" key={course._id}>
                <div className="card courses" style={{ height: "100%" }}>
                  <div className="course-content">
                    <Link to={`/course-details/${course.title.replace(/ /g, '-')}`}>
                      <img src={course.image ? course.image : frontendDeveloper} alt="course-img" className="img-fluid" style={{ height: '200px', width: '100%' }} />
                    </Link>
                  </div>
                  <div className="course-title p-2">
                    <Link to={`/course-details/${course.title.replace(/ /g, '-')}`}>
                      <p className="bold-p">{course.title.length > 20 ? course.title.slice(0, 20) + "..." : course.title}</p>
                    </Link>
                    <hr />
                    <p>
                      Duration: <span style={{ fontWeight: "500" }}>{course.duration}</span>
                    </p>
                    {course.instructorName ? (
                      <p>
                        Instructor Name: <span className="replaceStrong">{course.instructorName}</span>
                      </p>
                    ) : (
                      <p>
                        Instructor Name: <span className="replaceStrong">{course.name}</span>
                      </p>
                    )}
                  </div>
                  <div className="join mb-2">
                    <Link to={`/course-details/${course.title.replace(/ /g, '-')}`}>
                      <button className="first-button">View Contents </button>
                    </Link>
                    {signUser && signUser.name ? (
                      <button className="second-button float-end" onClick={() => Enrollment(course._id)}>
                        Enroll Now
                      </button>
                    ) : (
                      <Link to="/signin">
                        <button className="second-button float-end">
                          Enroll Now
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            })}
            <div className="d-flex justify-content-center">
              <Link to="/course"> <button className="second-button" style={{ borderRadius: "5px" }}>View More Courses</button></Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Corusecomponent;
